import Sidebar from "../SideBar";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from 'react';
import React from 'react';
import { Switch } from 'antd';
import { AiFillBackward } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CommonURL } from "../../commoncomponents/components";
import { Cookies } from 'react-cookie';


const cookies = new Cookies();

const SMSSetting = () => {
    const navigate = useNavigate();
    const [Smstemplate, setSmstemplate] = useState([]);
    const [IsEnable, setIsEnable] = useState(false);
    const [smsDelivery, setSmsDelivery] = useState(false);

    const Enable = (IsEnable) => {
        setIsEnable(IsEnable);
    };
    const SMSTemplate = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token };
        await fetch(`${CommonURL}/ShipSagarApi/GetSMSTemplate`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            let res = await response.json();
            setSmstemplate(res.getSMS);
        })
            .catch((err) => {
            });
    };

    const handleSaveSms = async (id) => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
      
            const smsDelivery = true
            const data = { ClientCode, Token, DeliveryTemplateID: id, DeliverySMS: smsDelivery };
            console.log(data,"data")
            await fetch(`${CommonURL}/ShipSagarApi/SaveSMSTemplate`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(async (response) => {
                const res = await response.json();
                if (res.status === "SUCCESS") {
                    setSmsDelivery(true)
                    toast.success(res.message, {
                        position: 'top-center'
                    }
                    )
                }
                else if (res.status === 'ERROR') {
                    if (res.message === "EXPIRED") {
                        navigate("/login");
                    } else {
                        setSmsDelivery(true)
                        toast.error(res.message, {
                            position: 'top-center'
                        })
                    }
                }
            })
                .catch((err) => {
                    toast.error(err.message, {
                        position: 'top-center'
                    })
                })
        
        
    }
    const GoBack = () => {
        navigate('/Settings')
    }
    useEffect(() => {
        SMSTemplate();
    }, []);

    return (
        <>
            <div className='vh-100'>
                <Sidebar>
                    <div className="container-fluid" data-aos="fade-down" data-aos-duration="2000">
                        <div className="row" style={{ "padding": "0px 12px" }} >
                            <div className="col-lg-12 main-Head">
                                <div className="page-title-box p-1">
                                    <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>SMS Settings</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-12 "  >
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row pt-1">
                                            <div className="col-sm-12"> 
                                            <button
                                                type="button"
                                                style={{ background: "#0d8787" }}
                                                className="btn text-light"
                                                onClick={GoBack}>
                                                <AiFillBackward />Back
                                                </button>
                                            </div>
                                            <div className='col-sm-12 d-flex pt-3' >
                                                <Switch
                                                    checked={IsEnable}
                                                    onChange={Enable}
                                                />
                                                <span style={{ color: "rgb(25, 76, 99)", "paddingLeft":"5px" }}>
                                                    <span className='fw-bold text-size'>Enable SMS on Delivery</span>
                                                </span>
                                            </div>
                                        </div>
                                        {IsEnable === true ?
                                            <div className='row bg-light mt-4' >
                                            <p style={{ "fontSize": "15px", "fontWeight": "bold" }}>Choose Template</p>
                                                {Smstemplate !== null ? (
                                                Smstemplate.map((sms,index) => (
                                                    <div className="row pb-3" key={index }>
                                                            <div className="col-sm-12">
                                                            <div className="col-md" style={{ "float": "left", "marginRight": "5px" }}>
                                                                <input type="radio" key={index} name='a'  checked={sms.DeliveryTemplateID} onChange={(e) => {
                                                                    setSmsDelivery(e.target.checked);
                                                                        handleSaveSms(sms.smsTemplateID);
                                                                        
                                                                    }} />
                                                                </div>
                                                                <div className="col-md-8" style={{ "float": "left" }}> 
                                                                    <label className="form-control" style={{ "minHeight": "150px", "width": "500px" }}>{sms.smsBody}</label>
                                                                </div>
                                                            </div>
                                                        </div>))
                                                ) : (
                                                        <div>No SMS Template available.</div>
                                                )}
                                                 
                                        </div>
                                        : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </Sidebar>
            </div>
        </>
   
    );
};
export default SMSSetting;