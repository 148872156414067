import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './custom.css';
import './components/variable.css';
import Login from './components/login/Login';
import Dashboard from './components/HomePage/Dashboard/Dashboard';
import Shipments from './components/HomePage/Shipments/Shipments';
import Tracking from './components/HomePage/Tracking/Tracking';
import Profile from './components/HomePage/Profile/Profile';
import ApiDocuments from './components/HomePage/ApiDocuments/ApiDocuments';
import CarrierPerformance from './components/HomePage/Report/CarrierPerformance';
import PageNotFound from './components/PageNotFount';
import ForgotPasswordSlide1 from './components/login/ForgotPasswordSlide1';
import ForgotPasswordSlide2 from './components/login/ForgotPasswordSlide2';
import TrackSetUp from './components/HomePage/Tracking/TrackSetUp';
import CourierList from './components/HomePage/Couriers/CourierList';
import WebTracking from './components/HomePage/Tracking/WebTracking';
import PaymentStatus from './components/HomePage/Payment/PaymentStatus';
import NDRReport from './components/HomePage/Report/NDR_Report';
import Settings from './components/HomePage/Settings/Settings';
import SMSSettings from './components/HomePage/Settings/SMSSettings';
import EmailSettings from './components/HomePage/Settings/EmailSettings';

const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const queryString = window.location.search;
    const shouldRenderRouter = queryString !== null && queryString !== '';

    return (
        <>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Login />} />
                <Route path='/checkdata' element={<ForgotPasswordSlide1 />} />
                <Route path='/forgotpassword' element={<ForgotPasswordSlide2 />} />
                <Route path='*' element={<ProtectedRoutes Component={PageNotFound} />} />
                <Route path='/dashboard' element={<ProtectedRoutes Component={Dashboard} />} />
                <Route path='/shipments' element={<ProtectedRoutes Component={Shipments} />} />
                <Route path='/CarrierPerformance' element={<ProtectedRoutes Component={CarrierPerformance} />} />
                <Route path='/NDR_Report' element={<ProtectedRoutes Component={NDRReport} />} />
                <Route path='/tracking' element={<Tracking />} />
                <Route path='/profile' element={<ProtectedRoutes Component={Profile} />} />
                <Route path='/apidocuments' element={<ProtectedRoutes Component={ApiDocuments} />} />
                <Route path='/trackingsetup' element={<ProtectedRoutes Component={TrackSetUp} />} />
                <Route path='/courierlist' element={<ProtectedRoutes Component={CourierList} />} />
                <Route path='/settings' element={<ProtectedRoutes Component={Settings} />} />
                <Route path='/SMSSettings' element={<ProtectedRoutes Component={SMSSettings} />} />
                <Route path='/EmailSettings' element={<ProtectedRoutes Component={EmailSettings} />} />
                <Route path='/webtracking' element={<WebTracking />} />
                {shouldRenderRouter ? (
                    <Route path="/paymentstatus" element={<ProtectedRoutes Component={PaymentStatus} />} />  
                ) : (
                    ''
                )}
            </Routes>
        </>
    )
}
export default App;
export const InputControl = ({ defaultValue, value, onChange, lableText, type, placeholder }) => {
    return (
        <>
            <div className='col-md-2 d-flex flex-column gap-1 ' >
                <label>{lableText}</label>
                <input type={type} value={value} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} className='form-control input-fields' />
            </div>
        </>
    )
}
