import Sidebar from "../SideBar";
import { ToastContainer, toast } from 'react-toastify';
import './EmailSettings.css';
import React, { useState, useEffect } from "react";
import { LoadingButton } from '@mui/lab';
import { FaSave } from 'react-icons/fa';
import { AiFillBackward } from "react-icons/ai";
import { CommonURL } from "../../commoncomponents/components";
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';
import { Editor } from "@tinymce/tinymce-react";

const cookies = new Cookies();

const EmailSettings = () => {
    const navigate = useNavigate();
    const [CustomerEmailID, setCustomerEmailID] = useState('');
    const [Host, setHost] = useState('');
    const [isDisable, setIsDisable] = useState(null);
    const [Port, setPort] = useState('');
    const [EmailFrom, setEmailFrom] = useState('');
    const [EmailPassword, setEmailPassword] = useState('');
    const [EmailSubject, setEmailSubject] = useState('');
    const [EmailPoint, setEmailPoint] = useState('');
    const [IsSecure, setIsSecure] = useState(true);
    const [EmailBody, setEmailBody] = useState('');
    const [emailTemplate, setEmailTemplate] = useState([]);
    const [getVariable, setGetVariable] = useState([]);
    let [IsEnable] = useState(false);

    const GoBack = () => {
        navigate('/Settings')
    };

    const SaveCustomerEmailSetting = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        let intPort = Port === '' ? 0 : Port
        const data = {
            ClientCode, Token, Host, Port: intPort, EmailFrom, EmailPassword, EmailSubject, EmailPoint, IsSecure, EmailBody, CustomerEmailID
        }
        await fetch(`${CommonURL}/ShipSagarApi/SaveEmailTemplate`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                toast.success(res.message, {
                    position: 'top-center'
                })
                setEmailPoint('')
                setEmailSubject('')
                setEmailBody('')
                setIsDisable(false)
                GetEmailTemplate()
                
                for (let i = 0; i < emailTemplate.length; i++) {
                    let a = document.getElementsByClassName('ram')[i].checked = false
                console.log(a)
                }
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    const handleSaveEmail = async (mailPoint, mailBody, active) => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        IsEnable = active
        const data = { ClientCode, Token, EmailPoint: mailPoint, IsEnable, EmailBody: mailBody };
        if (IsEnable === true) {

            await fetch(`${CommonURL}/ShipSagarApi/UpdateEmailTemplate`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(async (response) => {
                const res = await response.json();
                if (res.status === "SUCCESS") {
                    toast.success(res.message, {
                        position: 'top-center'
                    }
                    )
                }
                else if (res.status === 'ERROR') {
                    if (res.message === "EXPIRED") {
                        navigate("/login");
                    } else {
                        toast.error(res.message, {
                            position: 'top-center'
                        })
                    }
                }
            })
                .catch((err) => {
                    toast.error(err.message, {
                        position: 'top-center'
                    })
                })
        } else {

        }

    };

    const GetEmailTemplate = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token };
        await fetch(`${CommonURL}/ShipSagarApi/GetEmailTemplate`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            let res = await response.json();
            setEmailTemplate(res.getMail);
        })
            .catch((err) => {
                toast.error(err.message, {
                    position: 'top-center'
                })
            });
    };
    const GetVariables = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token };
        await fetch(`${CommonURL}/ShipSagarApi/GetVariable`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            let res = await response.json();
            setGetVariable(res.getVariable);
            console.log(res)
        })
            .catch((err) => {
                toast.error(err.message, {
                    position: 'top-center'
                })
            });
    };
    const handleClick = (i, id) => {

        console.log(id)
        setIsDisable(true)
        setHost(emailTemplate[i].host)
        setPort(emailTemplate[i].port)
        setEmailFrom(emailTemplate[i].emailFrom)
        setEmailPassword(emailTemplate[i].emailPassword)
        setEmailSubject(emailTemplate[i].emailSubject)
        setEmailPoint(emailTemplate[i].emailPoint)
        setIsSecure(Boolean(emailTemplate[i].isSecure))
        setEmailBody(emailTemplate[i].emailBody)
        setCustomerEmailID(id)

    };

    useEffect(() => {
        GetEmailTemplate();
        GetVariables();
    }, []);

    return (
        <>
            <div className='vh-100'>
                <Sidebar>
                    <div className="container-fluid" data-aos="fade-down" data-aos-duration="2000">
                        <div className="row" style={{ "padding": "0px 12px" }} >
                            <div className="col-lg-12 main-Head">
                                <div className="page-title-box p-1">
                                    <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>Email Settings For Customer</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-12"  >
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-sm-12 m-2">
                                            <button
                                                type="button"
                                                style={{ background: "#0d8787" }}
                                                className="btn text-light"
                                                onClick={GoBack}>
                                                <AiFillBackward />Back
                                            </button>
                                        </div>
                                        <div className='row py-2 px-3'>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mb-2">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>{/*<span style={{ color: "red" }}>*</span>*/}Host</b> (eg : smtp.gmail.com)</label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <input
                                                            type="text"
                                                            className="form-control col-md-7 col-xs-12"
                                                            tabIndex="1"
                                                            placeholder="Enter Host"
                                                            value={Host}
                                                            onChange={(e) => setHost(e.target.value)}
                                                            maxLength="50"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mb-2">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Port</b> (eg : 587)</label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <input
                                                            type="text"
                                                            className="form-control col-md-7 col-xs-12"
                                                            tabIndex="1"
                                                            placeholder="Enter Port No."
                                                            value={Port}
                                                            onChange={(e) => {
                                                                let { value } = e.target
                                                                const port = value.replace(/[^0-9]/g, '')
                                                                setPort(port)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mb-2">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Email From</b> (eg : abc12@gmail.com)</label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <input
                                                            type="text"
                                                            className="form-control col-md-7 col-xs-12"
                                                            tabIndex="1"
                                                            placeholder="Enter Email"
                                                            value={EmailFrom}
                                                            onChange={(e) => setEmailFrom(e.target.value)}
                                                            maxLength="150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mt-2">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Email Password</b> (eg : S@ipl123)</label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <input
                                                            type="text"
                                                            className="form-control col-md-7 col-xs-12"
                                                            tabIndex="1"
                                                            disabled={isDisable}
                                                            placeholder="Enter Password"
                                                            value={EmailPassword}
                                                            onChange={(e) => setEmailPassword(e.target.value)}
                                                            maxLength="50"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12 col-xs-12 mt-2">
                                                <div className="form-group mb-3">
                                                    <div className="col-md-4 col-sm-4 col-xs-12 mt-2">
                                                        <input
                                                            type="checkbox"
                                                            className="m-3"
                                                            tabIndex="1"
                                                            checked={IsSecure}
                                                            onChange={(e) => setIsSecure(e.target.checked)}
                                                        /><b>Secure</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="x_panel_button mt-1"></div>
                                            <div className="col-md-4 col-sm-12 col-xs-12 mt-3">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Email Point</b></label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <select className="form-control" value={EmailPoint} onChange={(e) => setEmailPoint(e.target.value)} tabIndex="1">
                                                            <option value=''>------select Email Point------</option>
                                                            <option value="Delivered">Delivered</option>
                                                            <option value="Undelivered">Undelivered</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mt-3">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Email Subject</b></label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12 mt-1">
                                                        <input
                                                            type="text"
                                                            className="form-control col-md-7 col-xs-12"
                                                            tabIndex="1"
                                                            placeholder="Enter Email Subject"
                                                            value={EmailSubject}
                                                            onChange={(e) => setEmailSubject(e.target.value)}
                                                            maxLength="150"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 col-xs-12 mt-3">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Variables :</b></label>
                                                    <div className="col-md-8 col-sm-8 col-xs-12">
                                                        {getVariable!==null?(getVariable.map((item, index) => 
                                                            <><span key='index'>{item.variables}</span><br /></>   
                                                        )):(<></>) }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12 mt-3">
                                                <div className="form-group">
                                                    <label className="control-label col-md-8 col-sm-8 col-xs-12"><b>Email Template</b></label>
                                                    <div className="row-fluid Editor-containerRow" tabIndex="1">
                                                        <Editor
                                                            initialValue={EmailBody}
                                                            init={{
                                                                branding: false,
                                                                cleanup: true,
                                                                height: 400,
                                                                menubar: true,
                                                                plugins:
                                                                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                                                toolbar:
                                                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                                                image_advtab: true,
                                                            }}
                                                            onChange={(editorState) => {
                                                                setEmailBody(
                                                                    (editorState.level.content)
                                                                    //.replace(/<[^>]*>/ig, ' ')
                                                                    //.replace(/<\/[^>]*>/ig, ' ')
                                                                    //.replace(/&nbsp;|&#160;/gi, ' ')
                                                                    //.replace(/\s+/ig, ' ')
                                                                    //.trim()
                                                                );
                                                            }}

                                                        />
                                                        <div id="statusbar" className="row-fluid" unselectable="on"></div>
                                                        <div className='text-left'>
                                                            <LoadingButton
                                                                size="small"
                                                                loadingPosition="center"
                                                                className="py-1"
                                                                style={{ background: "var(--mycolorOrange)" }}
                                                                variant="contained"
                                                                onClick={SaveCustomerEmailSetting}
                                                            >
                                                                <FaSave className='pe-1 fs-6' /> Save
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                    <div className="x_panel_button mt-1"></div>
                                                    <div className="row mt-3 " style={{ background: "#ededed" }}>
                                                        <p style={{ "fontSize": "15px", "fontWeight": "bold" }} className="pt-3">Choose Template -</p>
                                                        {emailTemplate !== null ? (
                                                            emailTemplate.map((email, index) => (
                                                                <div className="col-sm-4 mb-4" key={index}>
                                                                    <div className="card p-3 mb-2">
                                                                        <div className="col-sm-12 d-flex flex-row align-items-center pt-1 pb-1" style={{ background:"#dce1e3" }}>
                                                                            <div className="ms-2 c-details">
                                                                                <div style={{ float: "left" }}>
                                                                                    <input
                                                                                        className='ram'
                                                                                        type="radio"
                                                                                        key={index}
                                                                                        defaultChecked={false} 
                                                                                        name='a'
                                                                                        onClick={() => handleClick(index, email.customerEmailID)}
                                                                                    />
                                                                                </div>
                                                                                <h6 className="mb-0" style={{ float: "left", marginLeft: "5px" }}>{email.emailPoint}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-2 ps-2" style={{ border: "1px groove", overflowY: "auto", height: "120px", padding: "7px" }}>
                                                                            <p className="heading">{(email.emailBody).replace(/<[^>]*>/ig, ' ')
                                                                                .replace(/<\/[^>]*>/ig, ' ')
                                                                                .replace(/&nbsp;|&#160;/gi, ' ')
                                                                                .replace(/\s+/ig, ' ')
                                                                                .trim()}</p>
                                                                        </div>
                                                                        <div className="mt-2 d-flex justify-content-end">
                                                                            <label className="switch">
                                                                                <input type="checkbox"
                                                                                    key={index}
                                                                                    onChange={(e) => {
                                                                                        handleSaveEmail(email.emailPoint, email.emailBody, e.target.checked);
                                                                                    }}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div>No Email Template available.</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </Sidebar>
            </div>
        </>
    )
};
export default EmailSettings;

