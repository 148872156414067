import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"
import './ShipmentStyle.css';
import { CommonURL } from "../../commoncomponents/components";
import { LoadingButton } from '@mui/lab';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const AddShipments = (Props) => {
    const [TrackingNo, setTrackingNo] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [emailid, setEmail] = useState('');
    const [OrderNo, setOrderNo] = useState('');
    const [ShipmentType, setShipmentType] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [addstate, setAddstate] = useState(false);
    const [CountryName, setCountryName] = useState('');
    const [CompanyName, setCompanyName] = useState('');
    const [ClientCode, setClientCode] = useState('');
    const [CourierCode, setCourierCode] = useState('');
    const [courierArr, setCourierArr] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const navigate = useNavigate();

    const getCourierList = async () => {
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        const data = { clientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetCourierList`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                const data = res.getCourierList;
                setCourierArr(data)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }

        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    useEffect(() => {
        if (Props.data !== '') {
            setTrackingNo(Props.data.trackingNo)
            setCourierCode(Props.data.courierCode)
            setOrderNo(Props.data.orderNo)
            setCustomerName(Props.data.customerName)
            setMobileNo(Props.data.mobileNo)
            setEmail(Props.data.emailID)
            setCountryName(Props.data.countryName)
            setShipmentType(Props.data.shipmentType)
            setCompanyName(Props.data.companyName)
        }
    },[Props])

    const getCountryList = async () => {
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        setClientCode(clientCode)
        const data = { clientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetCountryList`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                const data = res.getCountryList;
                setCountryCode(data)
             
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    useEffect(() => {
        getCourierList();
        getCountryList();
    }, []);

    const addShipment = async () => {
        setAddstate(true)
        const Token = cookies.get('Token')
        let ClientCode = cookies.get('clientCode');
        let data = { Token, TrackingNo, CustomerName, emailid, OrderNo, ShipmentType, MobileNo, CountryName, CompanyName, ClientCode, CourierCode }
        await fetch(`${CommonURL}/ShipSagarApi/AddShipmentData`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            console.log(res)
            if (res.status === "Success") {
                setAddstate(false)
                toast.success(res.message, {
                    position: 'top-center'
                })
                setTrackingNo('')
                setCustomerName('')
                setEmail('')
                setOrderNo('')
                setShipmentType('')
                setMobileNo('')
                setCountryName('')
                setCompanyName('')
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setAddstate(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            setAddstate(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        })
    };

    return (
        <>
            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg " >
                    <div className="modal-content px-3">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Shipments</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>(fields marked with <span style={{ color: "red" }} >*</span> are mendatory)</p>
                            <div className="row" >
                                <div className="col-sm-6 ">
                                    <label>Client Code <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" required name='' readOnly value={ClientCode} className='form-control  p-1' /><br />
                                    <label>Tracking Number <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" name='' maxLength="30" value={TrackingNo} onChange={(e) => setTrackingNo(e.target.value)} className='form-control p-1' /><br />
                                    <label>Customer Name <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" maxLength="25" value={CustomerName} onChange={(e) => setCustomerName(e.target.value)} className='form-control p-1' /><br />
                                    <label>Email <span style={{ color: "red" }} >*</span></label>
                                    <input type="email" maxLength="100" value={emailid} onChange={(e) => setEmail(e.target.value)} className='form-control p-1' /><br />
                                    <label>Shipments Type <span style={{ color: "red" }} >*</span></label>
                                    <select className='form-control mb-4 p-1' value={ShipmentType} onChange={(e) => setShipmentType(e.target.value)} style={{ "WebkitAppearance": "push-button" }} >
                                        <option value="all" hidden></option>
                                        <option value="Road">By Road</option>
                                        <option value="Train">By Train</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 removebtnScpacing">
                                    <label>Choose Couriers <span style={{ color: "red" }} >*</span></label>
                                    <select className='form-control p-1 ' value={CourierCode} onChange={(e) => setCourierCode(e.target.value)} style={{ "WebkitAppearance": "push-button" }} >
                                        <option value="all" hidden></option>
                                        {
                                            courierArr.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.courierCode}>{item.courierName}</option>
                                                )
                                            })
                                        }
                                    </select><br />
                                    <label>Order ID <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" maxLength="25" value={OrderNo} onChange={(e) => setOrderNo(e.target.value)} className='form-control p-1' /><br />
                                    <label>Mobile Number <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" maxLength="15" value={MobileNo} onChange={(e) => setMobileNo(e.target.value)} className='form-control p-1' /><br />
                                    <label>Choose Country <span style={{ color: "red" }} >*</span></label>
                                    <select className='form-control p-1' value={CountryName} onChange={(e) => setCountryName(e.target.value)} style={{ "WebkitAppearance": "push-button" }} >
                                        <option value="all" hidden></option>
                                        {
                                            countryCode.map((item, index) => {                                         
                                                return (
                                                    <option key={index} value={item.countryName}>{item.countryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <br />
                                    <label>Company Name <span style={{ color: "red" }} >*</span></label>
                                    <input type="text" maxLength="50" placeholder='Enter Company Name' value={CompanyName} onChange={(e) => setCompanyName(e.target.value)} className='form-control p-1' /><br /><br />
                                </div>
                                <div className='row text-center'>
                                    <div className='col-md-10 mx-auto'>
                                        <LoadingButton
                                            loading={addstate}
                                            size="small"
                                            disabled={addstate}
                                            loadingPosition='center'
                                            tabIndex={1}
                                            onClick={addShipment}
                                            className='py-1 form-control w-50'
                                            style={{ background: "var(--mycolorOrange)" }}
                                            variant="contained">
                                            Add Shipment
                                        </LoadingButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default AddShipments;

