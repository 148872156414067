import React from 'react';
import { CgProfile } from 'react-icons/cg'
import { RiShutDownLine } from 'react-icons/ri'
import 'jspdf-autotable';
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

//export const CommonURL = 'https://localhost:7234/api';
export const CommonURL = 'https://app.shipsagar.com/api';

//export const PaymentURL = 'https://localhost:7234';
export const PaymentURL = 'https://app.shipsagar.com';

//export const FrontendURL = 'https://localhost:44430';
export const FrontendURL = 'https://app.shipsagar.com';

export const CommingSoon = () => {
    return (
        <>
            <section className="py-3">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12 mb-5 text-center">
                            <div className="thankyou-img">
                                <img src="./img/coming-soon.jpg" className="img-fluid" alt='' />
                            </div>
                            <div className="thankyou-text mt-3">
                                <h3 className="main-heading">We're Coming Soon</h3>
                                <p>We're working hard to give you the best experience!</p>
                                <Link to="/dashboard" className="btn text-light mt-3" style={{ background:'var(--mycolorBlue)' }}>
                                    Back to Dashboard
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



export const LogoutBtn = (props) => {
    const { handleClick } = props;
    let ClinetCode = cookies.get('clientCode');
    let ClientName = cookies.get('clientName');
    return (
        <div className='d-flex justify-content-between align-items-center gap-2 text-light' type='button'>
            <div className='p-2 text-light fw-bold'>{ClinetCode} : {ClientName}</div>
            <Link to='/profile'><div title='profile' className='fs-2 pb-2'><CgProfile className='text-light' /></div></Link>
            <div className='btn text-light fw-bold' onClick={handleClick} title="logout"><RiShutDownLine className="fs-3 " style={{ color: 'var(--mycolorOrange' }} /></div>
        </div>
    );
};

export const LoginBtn = () => {
    return (
        <div className='btn d-flex justify-content-between align-items-center gap-2 text-light' style={{ "background": "rgb(237, 114, 29)" }} type='button'>
            Login
        </div>
    )
}

export const SignUpBtn = () => {
    return (
        <div className='btn  d-flex justify-content-between align-items-center gap-2 text-light' style={{ "background": "rgb(237, 114, 29)" }} type='button'>
            Sign Up
        </div>
    )
}






