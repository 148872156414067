import { Timeline, } from 'antd';
import { CommonURL } from "../../commoncomponents/components"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from 'react-toastify';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { RiFilter3Fill } from "react-icons/ri";
import { MdSpatialTracking } from 'react-icons/md';
import { Card } from 'antd';
import { Cookies } from 'react-cookie';
import { useEffect } from 'react';

const cookies = new Cookies();
const WebTracking = () => {
    const navigate = useNavigate();
    const [CustomerQuery, setCustomerQuery] = useState([]);
    const [TrackingNo, setTrackingNo] = useState('');
    const [ButtonState, setButtonState] = useState(false);
    const [Question, setCQuestion] = useState('');
    const [Query, setQuery] = useState('');

    const [Showdata,setShowData]=useState([]);
 
    const Tracking = async () => {
        
        const url = window.location.href;
        let Token = url.split('#')[1];
        const data = {Token, TrackingNo }
        console.log(data,"ClientCode")
        await fetch(`${CommonURL}/Web/TrackShipment`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (response) => {
            let res = await response.json();
            if (res.trackingDetails) {
                let res1 = (res.trackingDetails)
                let data = (JSON.parse(res1));
                let data1 = (JSON.parse(data));
                var TrackingDetails = data1.TrackingHistory;
            }
            if (res.status === "SUCCESS") {
                setButtonState(true)
                if (Showdata.length === 0) {
                    for (let i = TrackingDetails.length - 1; i >= 0; i--) {
                        Showdata.push({
                            label: <p>{TrackingDetails[i].ActionDate}<br /><i className='text-secondary'>{TrackingDetails[i].ActionTime}</i></p>,
                            children: <p>{TrackingDetails[i].ActionDescription} <br /><i className='text-secondary'>{TrackingDetails[i].ActionLocation}</i></p>
                        })
                    }
                }
                GetCustomerQuery()
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                }
                else {
                    setShowData([])
                    setButtonState(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    const inputStyle = {
        borderRadius: '5px',
        height: '35px',
        borderColor: 'green',
        fontWeight: 'bold',
    };

    const GetCustomerQuery= async () => {

        const url = window.location.href;
        let Token = url.split('#')[1];
        let QuestionStatus = true
        const data = { Token, QuestionStatus }
        await fetch(`${CommonURL}/Web/GetCustomerQuery`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setCustomerQuery(res)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    toast.error()
                } else {
                    if (res.message !== "") {
                        toast.error(res.message, {
                            position: 'top-center'
                        })
                    }
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };


    const SendQuery = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token, Question, Query, TrackingNo };
        await fetch(`${CommonURL}/ShipSagarApi/SendQuery`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "Success") {
                setCQuestion('')
                setQuery('')
                toast.success(res.message, {
                    position: 'top-center'
                })
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    useEffect(() => {
        
    }, [])

    return (
        <>
            <div className='container-fluid bg-light' style={{ background: `url('./img/trackingback.jpg')`, backgroundRepeat: "noRepeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed" }}>
                <div className='row bg-light pb-3' style={{ position: "sticky", top: "0", zIndex : '1000' }}>
                    <br />
                    <div className="row">
                        <div className='col-sm-4'></div>
                        <div className='col-md-3 col-sm-12 pt-4 filter'>
                            <input
                                type="search"
                                className="form-control"
                                variant="contained"
                                value={TrackingNo}
                                maxLength="30"
                                onChange={(e) => setTrackingNo(e.target.value)}
                                placeholder="Enter Tracking Number"
                                style={inputStyle} />
                        </div>
                        <div className='col-md-5 filter'>
                            <LoadingButton
                                loadingPosition='center'
                                className='py-1'
                                onClick={Tracking}
                                style={{ background: "var(--mycolorOrange)" }}
                                variant="contained">
                                <span><RiFilter3Fill className='filter-icon' /></span>Track
                            </LoadingButton>
                            {CustomerQuery.queryEnable === true && ButtonState === true ?
                                <LoadingButton
                                    loadingPosition='center'
                                    className='py-1 me-2'
                                    style={{ background: "var(--mycolorBlue)", float: "right" }}
                                    variant="contained"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    data-bs-whatever="@mdo">
                                    <span><MdSpatialTracking className='filter-icon' /></span>
                                    Raise Query
                                </LoadingButton>
                                : ""}
                        </div>
                    </div>
                </div>
               
                <div className='row ' >
                    <div className='col-sm-10 pt-5'>
                        <Timeline
                            mode='left'
                            items={Showdata} />
                    </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            {CustomerQuery === undefined ? 'No question found' :
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-4" id="exampleModalLabel"> Raise Query</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" >
                                        <Card>
                                            <form>
                                                <div className="row">
                                                    <div className='col-sm-12'>
                                                        <select className='form-control py-0' value={ Question} onChange={(e) => setCQuestion(e.target.value)} style={{ "WebkitAppearance": "push-button", 'fontSize': "16px" }}>
                                                            <option hidden>Choose Question</option>

                                                            {CustomerQuery.getCustomerQuestion === undefined ? "" :
                                                                CustomerQuery.getCustomerQuestion.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.question}>{item.question}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <textarea
                                                            placeholder='Query Description'
                                                            value={Query }
                                                            onChange={(e) => setQuery(e.target.value)}
                                                            className="form-control mt-3"
                                                            style={{ minHeight: "80px" }}>
                                                        </textarea>
                                                    </div>
                                                </div>

                                            </form>
                                        </Card>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn text-light" onClick={SendQuery} style={{ background: "var(--mycolorOrange)" }} >Send Query</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};
export default WebTracking;


