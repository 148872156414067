import Sidebar from '../SideBar';
import { RiFilter3Fill, RiDownloadCloudFill } from "react-icons/ri";
import { CommonURL } from "../../commoncomponents/components";
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { ExportNDRReport } from '../Exports/Export';
import './CarrierPerformance.css';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const NDRReport = () => {
    let [state, setState] = useState(false);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const [reportData, setReportData] = useState([]);
    const [FromDate, setDateFrom] = useState(currentDate);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [ToDate, setDateTo] = useState(new Date());
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState('');
    const [totalRecord, setTotalRecord] = useState('');
    let noOfPage = Math.ceil(totalRecord / pageSize);

    const handleDateChangeTo = (date) => {
        setDateTo(date);
    };

    const handleDateChange = (date) => {
        setDateFrom(date);
    };

    const getReport = async (CurrentPage) => {
        setState(true)
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        let pageIndex = 1;
        if (CurrentPage !== undefined) {
            pageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const data = `{"ClientCode":"${ClientCode}","Token":"${Token}" ,"FromDate":"${document.getElementById("dateFrom").value}" ,"ToDate":" ${document.getElementById("dateTo").value} ","PageIndex": ${pageIndex}}`;
        await fetch(`${CommonURL}/Report/NDRReport`, {
            method: 'POST',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(async (response) => {
                let res = await response.json();
                if (res.status === "SUCCESS") {
                    setState(false)
                    const totalRecord = res.totalRecord;
                    const pageSize = res.pageSize;
                    setTotalRecord(totalRecord);
                    setReportData(res.getNDRReport)
                    setPageSize(pageSize);
                } else if (res.status === 'ERROR') {
                    setReportData([])
                    if (res.message === "EXPIRED") {
                        navigate("/login");
                    } else {
                        setState(false)
                        toast.error(res.message, {
                            position: 'top-center'
                        })
                        setTotalRecord(0);
                        setPageSize(1);
                    }
                }

            })
            .catch((err) => {
                setState(false)
                toast.error(err.message, {
                    position: 'top-center'
                })
            });
    };

    useEffect(() => {
        getReport();
    }, []);
    const handleChange = (event, value) => {
        getReport(value);
    };
    const handleDownloadpdf = async () => {
        const apiUrl = `${CommonURL}/Report/NDRReport`;
        const requestBody = {
            Token: cookies.get('Token'),
            clientCode: cookies.get('clientCode'),
            FromDate: document.getElementById("dateFrom").value,
            ToDate: document.getElementById("dateTo").value,
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        const records = await ExportNDRReport(apiUrl, requestBody, requestHeaders);
        if (records) {
            downloadPDF(records);
        }
    };

    const downloadPDF = (records) => {
        const doc = new jsPDF({ orientation: 'landscape' });
        const tableData = records.map((record) => [
            record.trackingNo, record.orderNo, record.currentStatus, record.ndr, record.courierCode, record.client, record.countryName, record.companyName, record.createdDate
        ]);
        doc.autoTable({
            head: [
                ['Tracking No', 'Order No', 'Current Status', 'NDR', 'Courier Code', 'Client', 'Country Name', 'Company Name', 'createdDate'
                ],
            ],
            body: tableData,
        });
        doc.save('AllRecords.pdf');
    };

    const handleDownloadExcel = async () => {
        const apiUrl = `${CommonURL}/Report/NDRReport`;
        const requestBody = {
            Token: cookies.get('Token'),
            clientCode: cookies.get('clientCode'),
            FromDate: document.getElementById("dateFrom").value,
            ToDate: document.getElementById("dateTo").value,
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        const records = await ExportNDRReport(apiUrl, requestBody, requestHeaders);
        if (records) {
            downloadExcel(records);
        }
    };

    const downloadExcel = (records) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Tracking No', 'Order No', 'Current Status', 'NDR', 'Courier Code', 'Client', 'Country Name', 'Company Name', 'createdDate'
            ],
            ...records.map((record) => [record.trackingNo, record.orderNo, record.currentStatus, record.ndr, record.courierCode, record.client, record.countryName, record.companyName, record.createdDate
            ]),
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveExcelFile(excelBuffer, 'AllRecords.xlsx');
    };

    const saveExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <Sidebar>
                <div className='container-fluid' data-aos="fade-down" data-aos-duration="2000" >
                    <h6 className='fw-bold' style={{ color: '#194c63' }}>NDR Report</h6>
                    <div className='row bg-light rounded-top shadow d-flex p-3 pb-0' >
                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-md-3 d-flex flex-column gap-1 ' >
                                    <label>Date From</label>
                                    <DatePicker id="dateFrom" className='form-control' dateFormat="dd-MMM-yyyy" selected={FromDate} onChange={handleDateChange} />
                                </div>
                                <div className='col-md-3 d-flex flex-column gap-1 ' >
                                    <label>Date To</label>
                                    <DatePicker id="dateTo" className='form-control' dateFormat="dd-MMM-yyyy" selected={ToDate} onChange={handleDateChangeTo} />
                                </div>
                                <div className='col-md-6 filter'>
                                    <LoadingButton
                                        loading={state}
                                        disabled={state}
                                        loadingPosition='center'
                                        onClick={() => getReport(currentPageNo)}
                                        className='py-1'
                                        style={{ background: "var(--mycolorOrange)" }}
                                        variant="contained">
                                        <span><RiFilter3Fill className='filter-icon' /></span>Apply
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-2'>
                            <div className='col-sm-4 pt-3'><span className='m-0 ps-2' style={{ "fontFamily": "Calibri", "letterSpacing": ".8px", "fontSize": "17px" }}>Total : <b>{totalRecord} Records</b></span></div>
                            <div className="col-md-8 d-flex justify-content-end">
                                <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
                                    <button className='text-light btn' style={{ background: "var(--mycolorBlue)" }}>
                                        <span><RiDownloadCloudFill className='shipment-icon' /></span>
                                        <span className='text-center fw-normal'>Download NDR Report</span>
                                    </button>
                                    <div className="dropdown-content con2">
                                        <a onClick={handleDownloadpdf}>PDF</a>
                                        <a onClick={handleDownloadExcel}>EXCEL</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row bg-light rounded-bottom pt-3' >
                        <div className='col-sm-12' style={{ "overflowX": "auto" }}>
                            <table className='table table-bordered text-left table-striped' style={{ "fontSize": "12px" }}  >
                                <thead className='text-light' style={{ "background": "#194c63" }}>
                                    <tr>
                                        <th>Tracking No.</th>
                                        <th>Order No.</th>
                                        <th>Current Status</th>
                                        <th>NDR</th>
                                        <th>Courier Code</th>
                                        <th>Customer Name</th>
                                        <th>Country Name</th>
                                        <th>Company Name</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    reportData === null ? '' :
                                        reportData.map((item, index) =>
                                            <tr key={index}>
                                                <td >{item.trackingNo}</td>
                                                <td>{item.orderNo}</td>
                                                <td>{item.currentStatus}</td>
                                                <td>{item.ndr}</td>
                                                <td>{item.courierCode}</td>
                                                <td>{item.client}</td>
                                                <td>{item.countryName}</td>
                                                <td>{item.companyName}</td>
                                                <td>{item.createdDate}</td>

                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="row">
                                <div className='col-sm-12 pb-2'>
                                    <Stack spacing={2}>
                                        <Pagination count={noOfPage} color="primary" page={currentPageNo} onChange={handleChange} />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer />
            </Sidebar>
        </>
    )
}
export default NDRReport;
export const InputControl = ({ defaultValue, value, onChange, lableText, type, placeholder }) => {
    return (
        <>
            <div className='col-md-3 d-flex flex-column gap-1' >
                <label>{lableText}</label>
                <input type={type} value={value} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} className='form-control input-fields' />
            </div>
        </>
    )
}