import { LogoutBtn, SignUpBtn } from "../commoncomponents/components"
import { CommonURL } from "../commoncomponents/components"
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Header = (Props) => {
    const isLogin = Props.isLogin;
    const handleClick = Props.handleClick; 
    let ClientCode = cookies.get('clientCode');
    let Token = cookies.get('Token');
    const navigate = useNavigate();
    const [Logo, setLogo] = useState('')

    const getProfile = async () => {
        let data = { ClientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setLogo(res.clientLogo);
                
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    useEffect(() => {
        getProfile();
    },[])


    return (
        <>
            <header className='bg-dark p-1 d-flex justify-content-between align-items-center'>
                <div className='bg-dark p-1 '>
                    <img src={ Logo} height="45px" width='60px' className='rounded p-1 bg-light' alt='...' />
                </div>
                <div className='d-flex gap-2'>
                    {isLogin ? (<LogoutBtn handleClick={handleClick} />):(<SignUpBtn />)}
                </div>
            </header>
        </>
    )
}
export default Header;