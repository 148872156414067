import Sidebar from "../SideBar";
import './Settings.css';
import {useNavigate } from 'react-router-dom'

const Settings = () => {
    const navigate = useNavigate();
    const trackingSetUp = () => {
        navigate('/trackingsetup')
    }
    const courierlist = () => {
        navigate('/courierlist')
    }
    const smsSettings = () => {
        navigate('/SMSSettings')
    }
    const emailSettings = () => {
        navigate('/EmailSettings')
    }

    return (
        <>
            <Sidebar>
                <div className="container-fluid" data-aos="fade-down" data-aos-duration="2000">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-box">
                                <span className="fw-bold fs-6" style={{ "color": "rgb(25, 76, 99)" }}>Settings</span>
                            </div>
                        </div>
                    </div>
                    <div className="row setting-card mt-2">
                        <div className="col-lg-3" style={{"cursor":"pointer"} }>
                            <div className="m-2 card-box" onClick={trackingSetUp }>
                                <div className="card-body" style={{ "padding":"10px 10px","height":"145px"}} >
                                    <div className="align-items-center row">
                                        <div className="col-auto" style={{ "width": "100%", "marginTop":"10px" }} >
                                            <div className="float-left icon_box">
                                                <img src="./img/Track_shipment.png" style={{ "width": "20px" }} alt=""/>
                                            </div>
                                            <h2 className="header-title text_dark mt-2 ml-2 fw-bolder">Tracking SetUp</h2>
                                        </div>
                                        <div className="col-auto mt-2" style={{ "width": "98%" }}>
                                            <p className="small text_para mb-1 w-100">Set tracking details that you want to show on widget results page. Find widget code and paste it on your website’s page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ "cursor": "pointer" }}>
                            <div className="m-2 card-box" onClick={courierlist}>
                                <div className="card-body" style={{ "padding": "10px 10px", "height": "145px"}} >
                                    <div className="align-items-center row">
                                        <div className="col-auto" style={{ "width": "100%", "marginTop": "10px" }} >
                                            <div className="float-left icon_box">
                                                <img src="./img/Carrier_list.png" style={{ "width": "20px" }} alt="" />
                                            </div>
                                            <h2 className="header-title text_dark mt-2 ml-2 fw-bolder">Carrier List</h2>
                                        </div>
                                        <div className="col-auto mt-2" style={{ "width": "98%" }}>
                                            <p className="small text_para mb-1 w-100">Set tracking details that you want to show on widget results page. Find widget code and paste it on your website’s page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ "cursor": "pointer" }}>
                            <div className="m-2 card-box" onClick={smsSettings}>
                                <div className="card-body" style={{ "padding": "10px 10px", "height": "145px" }} >
                                    <div className="align-items-center row">
                                        <div className="col-auto" style={{ "width": "100%", "marginTop": "10px" }} >
                                            <div className="float-left icon_box">
                                                <img src="./img/SMS.png" style={{ "width": "20px" }} alt="" />
                                            </div>
                                            <h2 className="header-title text_dark mt-2 ml-2 fw-bolder">SMS Settings</h2>
                                        </div>
                                        <div className="col-auto mt-2" style={{ "width": "98%" }}>
                                            <p className="small text_para mb-1 w-100">Set tracking details that you want to show on widget results page. Find widget code and paste it on your website’s page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ "cursor": "pointer" }}>
                            <div className="m-2 card-box" onClick={emailSettings}>
                                <div className="card-body" style={{ "padding": "10px 10px", "height": "145px" }} >
                                    <div className="align-items-center row">
                                        <div className="col-auto" style={{ "width": "100%", "marginTop": "10px" }} >
                                            <div className="float-left icon_box">
                                                <img src="./img/Email.jpg" style={{ "width": "20px" }} alt="" />
                                            </div>
                                            <h2 className="header-title text_dark mt-2 ml-2 fw-bolder">Email Settings</h2>
                                        </div>
                                        <div className="col-auto mt-2" style={{ "width": "98%" }}>
                                            <p className="small text_para mb-1 w-100">Set tracking details that you want to show on widget results page. Find widget code and paste it on your website’s page.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
};
export default Settings;