import Sidebar from "../SideBar";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const PaymentStatus = () => {    const [time, setTime] = useState(15);    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const PaymentStatus = queryParams.get('PaymentStatus');
    const PaymentMsg = queryParams.get('PaymentMsg');
    const TxnID = queryParams.get('TxnID');
    const PaymentDateTime = queryParams.get('PaymentDateTime');
    const navigate = useNavigate();

    useEffect(() => {
        if (time > 0) {
            const timerID = setTimeout(() => {
                setTime(time - 1);
            }, 1000);

            return () => {
                clearTimeout(timerID);
            };
        } else {
            navigate('/dashboard')
        }
    }, [time, navigate]);
    return (        <>            <Sidebar>                <section className="py-3">                    <div className="container">                        <div className="row pt-5 mt-5">                            <div className="col-12 text-center">                                <div className="thankyou-img">                                    {PaymentStatus === 'SUCCESS' ?                                        <img                                            src="./img/success.png"                                            className="img-fluid"                                            style={{ maxWidth: 150 }}                                            alt='...'                                        /> :                                        <img                                            src="./img/fail.png"                                            className="img-fluid"                                            style={{ maxWidth: 150 }}                                            alt='...'                                        />                                    }                                </div>                                <div className="thankyou-text mt-3">                                    <h3 className="main-heading fw-bold">{PaymentStatus}</h3>                                    <p>                                        <span className='fs-6'>{PaymentMsg}</span>                                        <br />                                        <span>You will be redirect in [dashboard] after {time} Sec </span>                                    </p>                                    <Link to='/dashboard'> <button type="submit" className="btn mt-2 text-light mx-1" style={{ background: 'var(--mycolorOrange)' }}>
                                        Go To Dashboard
                                    </button></Link>
                                    <Link to='/dashboard' target='_blank'><button type="submit" className="btn mt-2 mx-1 text-light" style={{ background: 'var(--mycolorBlue)' }}>
                                        Print Recipt
                                    </button></Link>                                </div>                            </div>                        </div>                    </div>                </section>            </Sidebar>        </>    )}export default PaymentStatus;