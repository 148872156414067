import React from 'react';
import Sidebar from '../SideBar';
import { useState, useEffect } from 'react';
import { Switch } from 'antd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FaSave } from 'react-icons/fa';
import { BsPersonAdd } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa';
import copy from "copy-to-clipboard";
import { RiEdit2Fill } from 'react-icons/ri';
import { AiFillDelete, AiFillBackward } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import { CommingSoon, CommonURL } from "../../commoncomponents/components";
import { ToastContainer, toast } from 'react-toastify';
import './tractSetup.css';
import { Cookies } from 'react-cookie';
import WebTracking from './WebTracking';
const cookies = new Cookies();

const TrackSetUp = () => {
    const [QuestionObject, setQuestionObject] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [IsEnable, setIsEnable] = useState(false);
    const [ID, setID] = useState();
    const [State, setState] = useState(false);
    const [isCustomerClicked, setIsCustomerClicked] = useState(false);
    const [isTrackingClicked, setIsTrackingClicked] = useState(false);
    const [isBrandedTrackingClicked, setIsBrandedTrackingClicked] = useState(false);
    const [query, setQuery] = useState('')
    const [emailTo, setEmailTo] = useState('')
    const [emailCC, setEmailCC] = useState('')
    const [question, setQuestion] = useState('')
    const [TrackingWidget, setTrackingWidget] = useState('')
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    var newData = JSON.parse(localStorage.getItem("newData")) || [];

    const brandedTrackingClick = () => {
        setIsBrandedTrackingClicked(true)
        setIsTrackingClicked(false);
        setIsCustomerClicked(false);
        setActiveTab(1);
    };

    const onChange = (isActive) => {
        setIsActive(isActive);
    };

    const Enable = (IsEnable) => {
        setIsEnable(IsEnable);
    };

    const Record = () => {
        if (question.length < 3) {
            toast.error('Please Enter a valid question!')
        } else {
            const data = { question, isActive };
            QuestionObject.push(data);
            localStorage.setItem("newData", JSON.stringify(QuestionObject));
            setQuestion('');
            setIsActive(false);
        }
    };

    const customerClick = () => {
        setIsBrandedTrackingClicked(false)
        setIsCustomerClicked(true);
        setIsTrackingClicked(false);
        setActiveTab(2);
    };

    const trackingClick = () => {
        setIsBrandedTrackingClicked(false)
        setIsTrackingClicked(true);
        setIsCustomerClicked(false);
        setActiveTab(0);
    };

    const copyToClipboard = () => {
        copy(TrackingWidget);
        toast.success(`TrackingWidget Copied!`, {
            position: 'top-center'
        })
    };

    const handleTextareaChange = (event) => {
        setTrackingWidget(event.target.value);
    };

    const getTrackingWidget = async () => {
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        const data = { clientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/TrackingWidget`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                const data = res.trackingWidget ;

                setTrackingWidget(data)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    const SaveCustomerQuerySetting = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        let QueryEnable = IsEnable;
        let EmailID = emailTo;
        let EmailCC = emailCC;
        let AfterOrderDays = Number(query);
        let QuestionList = JSON.parse(localStorage.getItem('newData'))
        const data = { ClientCode, Token, QueryEnable, EmailID, EmailCC, AfterOrderDays, QuestionList }
        await fetch(`${CommonURL}/ShipSagarApi/SaveCustomerQuerySetting`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "Success") {
                toast.success(res.message, {
                    position: 'top-center'
                })
                localStorage.clear()
                GetCustomerQuerySetting();
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
             
        });
    };
    function editQuestion(id) {
        setID(id)
        setQuestion(newData[id].question)
        setIsActive(newData[id].isActive)
        setState(true)
    }
    function deleteQuestion(id) {
        let updatedData = [...QuestionObject]
        updatedData.splice(id, 1)
        localStorage.setItem("newData", JSON.stringify(updatedData));
        setQuestionObject(updatedData)
    }
    function updateQuestion() {
        const data = { question, isActive };
        let updatedData = [...QuestionObject]
        updatedData[ID] = data
        setQuestionObject(updatedData)
        localStorage.setItem("newData", JSON.stringify(updatedData));
        toast.success('Question Updated Succesfully!', {
            position: 'top-center'
        })
        setQuestion('');
        setIsActive(false);
        setState(false)
    }

    const GetCustomerQuerySetting = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        const data = { ClientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetCustomerQuerySetting`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setQuery(res.afterOrderDays)
                setEmailTo(res.emailID)
                setEmailCC(res.emailCC)
                localStorage.setItem("newData", JSON.stringify(res.getCustomerQuestion));
                setIsEnable(res.queryEnable)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {

                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };
    const GoBack = () => {
        navigate('/Settings')
    }

    useEffect(() => {
        trackingClick()
        getTrackingWidget();
        setQuestionObject(newData)
        GetCustomerQuerySetting()
    }, []);

    return (
        <div className='vh-100'>
            <Sidebar>
                <div className="container-fluid" data-aos="fade-down" data-aos-duration="2000">
                    <div className="row" style={{ "padding": "0px 12px" }} >
                        <div className="col-lg-12">
                            <div className="page-title-box">
                                <div className="row">
                                    <div className="col-lg-12 track-tabs-header ">
                                        <div className="page-title-box ">
                                            <Tabs variant="scrollable" scrollButtons="auto" className="custom-tabs">
                                            
                                                <hr style={{ width: "2px", height: "20px" }} />
                                                <Tab
                                                    label="Tracking"
                                                    onClick={trackingClick}
                                                    className={`heading mb-sm-0 fw-bold tab-item tab-style ${activeTab === 0 ? 'active' : ''}`} >
                                                    Tracking
                                                </Tab>
                                                <hr style={{ width: "2px", height: "20px" }} />
                                                <Tab
                                                    label="Branded Tracking"
                                                    onClick={brandedTrackingClick}
                                                    className={`mb-sm-0 heading fw-bold tab-item tab-style ${activeTab === 1 ? 'active' : ""}`}>
                                                    Branded Tracking
                                                </Tab>
                                                <hr style={{ width: "2px", height: "20px" }} />
                                                <Tab
                                                    label="Customer Query"
                                                    onClick={customerClick}
                                                    className={`mb-sm-0 heading fw-bold tab-item tab-style ${activeTab === 2 ? 'active' : ''}`}>
                                                    Customer Query
                                                </Tab>
                                                <hr style={{ width: "2px", height: "20px" }} />
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                           
                            {isTrackingClicked && (
                                <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row pt-4">
                                            <div className="col-lg-3 pt-2">
                                                <b style={{ color: "var(--mycolorBlue)", fontSize: "16px", }}>
                                                    Tracking Component
                                                    <span className="mandatory">*</span>
                                                </b>
                                            </div>
                                            <div className="col-lg-6">
                                                <textarea
                                                    className="form-control"
                                                    style={{ minHeight: "90px" }}
                                                    id="query"
                                                    maxLength='500'
                                                    value={TrackingWidget}
                                                    onChange={handleTextareaChange}>
                                                </textarea>
                                                <div style={{ float: "right" }}>
                                                    <button
                                                        type="button"
                                                        style={{ background: "var(--mycolorBlue)" }}
                                                        className="btn text-light mt-2 float-right"
                                                        onClick={copyToClipboard}>
                                                        <FaRegCopy />Copy
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </>
                            )}

                            {isBrandedTrackingClicked && (
                                <CommingSoon/>
                            )}

                            {isCustomerClicked && (
                                <div className="row mb-3">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className='d-flex py-2 justify-content-between' >
                                                <span className='fw-bold text-size ms-3' style={{ color: '#194c63' }}>Customer Query</span>
                                                <div style={{ float: "right" }} className=' pe-2 d-flex'>
                                                    <span style={{ color: "rgb(25, 76, 99)" }}>
                                                        <span className='fw-bold text-size'>Disable/Enable</span>
                                                    </span>
                                                    <Switch
                                                        checked={IsEnable}
                                                        className='ms-2'
                                                        onChange={Enable}
                                                    />
                                                </div>
                                            </div>
                                            {IsEnable === true ?
                                                <div className="card-body">
                                                    <div className='row pt-3' >
                                                        <div className='col-sm-6 px-5'>
                                                            <b style={{ color: "rgb(25, 76, 99)" }}>Query After Order Days</b>
                                                            <input type="text" maxLength='4' placeholder="Query after order days" value={query} className="form-control mb-3" onChange={(e) => setQuery(e.target.value)} />
                                                            <b style={{ color: "rgb(25, 76, 99)" }}>Email To</b><span className="mandatory">*</span>
                                                            <input type="email" maxLength='100' placeholder="Email To" value={emailTo} onChange={(e) => setEmailTo(e.target.value)} className="form-control mb-3" />
                                                            <b style={{ color: "rgb(25, 76, 99)" }}>Email Cc</b><span className="mandatory">*</span>
                                                            <input type="email" maxLength='100' placeholder="Email Cc" onChange={(e) => setEmailCC(e.target.value)} value={emailCC} className="form-control mb-4" />

                                                            <div className='text-center'>
                                                                <LoadingButton
                                                                    size="small"
                                                                    loadingPosition="center"
                                                                    tabIndex="1"
                                                                    className="py-1"
                                                                    style={{ background: "var(--mycolorOrange)" }}
                                                                    variant="contained"
                                                                    onClick={SaveCustomerQuerySetting}
                                                                >
                                                                    <FaSave className='pe-1 fs-6' /> Submit & Save
                                                                </LoadingButton>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 pt-3">
                                                            <div className="x_panel">
                                                                <b className='fs-6' style={{ color: "rgb(25, 76, 99)" }}>Question:</b>
                                                                <span className="d-flex align-items-center gap-2 mb-4">
                                                                    <input type="text"
                                                                        placeholder="Add Question"
                                                                        className="form-control"
                                                                        value={question}
                                                                        onChange={(e) => setQuestion(e.target.value)} />
                                                                    <Switch className='ms-2' checked={isActive} onChange={onChange} />
                                                                    {State === true ?
                                                                        <LoadingButton
                                                                            className=''
                                                                            size="small"
                                                                            loadingPosition="center"
                                                                            style={{ background: "var(--mycolorBlue)" }}
                                                                            variant="contained"
                                                                            onClick={updateQuestion}
                                                                        >
                                                                            UPDATE
                                                                        </LoadingButton>
                                                                        :
                                                                        <LoadingButton
                                                                            className=''
                                                                            size="small"
                                                                            loadingPosition="center"
                                                                            style={{ background: "var(--mycolorBlue)" }}
                                                                            variant="contained"
                                                                            onClick={Record}
                                                                        >
                                                                            <BsPersonAdd className='me-1 fs-5' /> ADD
                                                                        </LoadingButton>
                                                                    }
                                                                </span>
                                                                <div className="x_title">
                                                                    <h2>Customer Question</h2>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="">
                                                                    {QuestionObject.length === 0 ? "" :
                                                                        <table className="table table-hover border">
                                                                            <thead style={{ background: "#dee1e6" }} >
                                                                                <tr>
                                                                                    <th>Action</th>
                                                                                    <th> Customer Question</th>
                                                                                    <th className='text-right'> Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    QuestionObject.map((item, index) =>
                                                                                        <tr key={index}>
                                                                                            <td><RiEdit2Fill className='fs-6 text-success me-2 pointer' onClick={() => editQuestion(index)} />
                                                                                                <AiFillDelete className='fs-6 text-danger pointer' onClick={() => deleteQuestion(index)} />
                                                                                            </td>
                                                                                            <td>{item.question}</td>
                                                                                            <td className='text-right'>{item.isActive === true ? <span className=' text-success fw-bold'>Active</span> : <span className='fw-bold text-danger'>Disable</span>}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    style={{ background: "#0d8787", margin: "10px" }}
                    className="btn text-light"
                    onClick={GoBack}>
                    <AiFillBackward />Back
                </button>
                <ToastContainer />
            </Sidebar>
        </div >
    );
};

export default TrackSetUp;
