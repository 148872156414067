import React, { useState, useEffect } from 'react';
import { CommonURL, PaymentURL, FrontendURL } from "../../commoncomponents/components";
import './Payment.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Cookies } from 'react-cookie';
import Swal from 'sweetalert2';


const cookies = new Cookies();
const Payment = () => {
    const [Balance, setBalance] = useState([]);
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    let clientCode = cookies.get('clientCode');

    const makePayment = () => {
        if (amount < 100 || amount>1000000 ) {
            setError('please Enter valid amount value!')
        } else {
            window.location.href = (`${PaymentURL}/payment/MakePayment?ClientCode=${clientCode}&Amount=${amount}&ReturnURL=${FrontendURL}/paymentstatus`)
            setError('')
        }
    };

    const getBalance = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');

        const data = { ClientCode, Token };

        try {
            const response = await fetch(`${CommonURL}/ShipSagarApi/GetBalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error('API request failed');
            }
            const responseData = await response.json();
            //console.log('API response:', responseData.remainingBalance);
            setBalance(responseData);
        } catch (error) {
            //console.error('API error:', error);
        }
    };

    const handleRechargeClick = () => {
        const rechargeButton = document.getElementById('recharge-button');
        const input = document.getElementById('payment-input');
        const makePaymentButton = document.getElementById('make-payment-button');

        rechargeButton.style.display = 'none';
        input.style.display = 'block';
        makePaymentButton.style = 'block';
    };

    useEffect(() => {
        getBalance();
    }, []);

    return (
        <div className='d-flex'>
            <span style={{ fontSize: '15px' }}><b>Balance: &#8377;<span className='text-success'> {Balance.remainingBalance} </span></b></span> &nbsp; &nbsp;
            <button className='btn text-light w-25' style={{ background: "var(--mycolorBlue)" }} id='recharge-button' onClick={handleRechargeClick}>
                Recharge
            </button>
            <div className='d-flex gap-2 payment-box' >
                <div>
                    <input type='number' value={amount} onChange={(e) => setAmount(e.target.value)} className='form-control' id='payment-input' style={{ display: 'none' }} />
                    <span className='abc text-danger'>{error}</span>
                </div>
                <button className='btn text-light py-1' onClick={makePayment} id='make-payment-button' style={{ display: 'none' }}>
                    Make Payment
                </button>
            </div>

            <ToastContainer />
        </div>
    );
};

export default Payment;
