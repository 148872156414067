import React, { useState } from 'react';
import { FaBars, FaUserAlt, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { TbTruckDelivery, TbApi, TbReportSearch } from 'react-icons/tb';
import { NavLink, useNavigate } from 'react-router-dom';
import Shipments from './Shipments/Shipments';
import Dashboard from './Dashboard/Dashboard';
import ApiDocuments from './ApiDocuments/ApiDocuments';
import Profile from './Profile/Profile';
import Header from './Header';
import CarrierPerformance from './Report/CarrierPerformance';
import { BiNotepad } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
import { CgTrack } from 'react-icons/cg';
import { MdSpatialTracking } from 'react-icons/md';
import TrackSetUp from './Tracking/TrackSetUp';
import CourierList from './Couriers/CourierList';
import NDRReport from './Report/NDR_Report';
import Settings from './Settings/Settings';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Sidebar = ({ children }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const navigate = useNavigate();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = async () => {
        await cookies.remove('Token');
        await cookies.remove('clientName');
        await cookies.remove('clientCode');
        navigate('/login');
        setIsLogin(false);
    };

    const handleSubMenuClick = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };

    const menuItem = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: <AiFillHome />,
            page: <Dashboard />,
            title: 'Dashboard',
        },
        {
            path: '/shipments',
            name: 'Shipments',
            icon: <TbTruckDelivery />,
            page: <Shipments />,
            title: 'Shipments',
        },
        {
            path: '/CarrierPerformance',
            name: 'Carrier Performance',
            icon: <TbReportSearch />,
            page: <CarrierPerformance />,
            title: 'Carrier Performance',
        },
        {
            path: '/NDR_Report',
            name: 'NDR Report',
            icon: <BiNotepad />,
            page: <NDRReport />,
            title: 'NDR Report',
        },
        {
            path: '/profile',
            name: 'Profile',
            icon: <FaUserAlt />,
            page: <Profile />,
            title: 'Profile',
        },
        {
            path: '/Settings',
            name: 'Settings',
            icon: <AiFillSetting />,
            page: <Settings />,
            title: 'Settings',
        },
        {
            path: '/apidocuments',
            name: 'API Docs',
            icon: <TbApi />,
            page: <ApiDocuments />,
            title: 'API Docs',
        },
    ];

    return (
        <>
            <Header isLogin={isLogin} handleClick={handleClick} />
            <div className="container-nav">
                <div
                    style={{ width: isOpen ? '250px' : '50px', position: 'sticky', top: '0px', right: '0px' }}
                    className="sidebar" >
                    <div className="top_section">
                        <h5 style={{ display: isOpen ? 'block' : 'none' }} className="logo">
                            ShipSagar
                        </h5>
                        <div style={{ marginLeft: isOpen ? '70px' : '0px' }} className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    {menuItem.map((item, index) => (
                        <div key={index}>

                            {item.submenu ? (
                                <div className="link" onClick={() => handleSubMenuClick(index)}>
                                    <div className="icon">{item.icon}</div>
                                    <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                                        {item.name}
                                    </div>
                                    {activeSubMenu === index ? <FaAngleUp className='mt-1' /> : <FaAngleDown className='mt-1' />}

                                </div>
                            ) : (
                                <div title={item.title}>
                                    <NavLink
                                        to={item.path}
                                        className="link"
                                        activeclassname={activeSubMenu === index ? 'active' : ''}
                                        onClick={() => handleSubMenuClick(index)}>
                                        <div className="icon" >{item.icon}</div>
                                        <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                                            {item.name}
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                            {item.submenu && activeSubMenu === index && (
                                <div className="submenu ms-3" >
                                    {item.submenu.map((subItem, subIndex) => (
                                        <NavLink
                                            to={subItem.path}
                                            key={subIndex}
                                            className="link"
                                            activeclassname="active"
                                        >
                                            <div className="icon" >{subItem.icon}</div>
                                            <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                                                {subItem.name}
                                            </div>
                                        </NavLink>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <main>{children}</main>
            </div>
        </>
    );
};

export default Sidebar;