import 'react-toastify/dist/ReactToastify.css';
import './ShipmentStyle.css';
import { CommonURL } from "../../commoncomponents/components";
import { useNavigate } from "react-router-dom"
import { RiUploadCloudFill, RiDownloadCloudFill } from "react-icons/ri";
import *as xlsx from 'xlsx';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const BulkAddShipments = () => {
    const [Records, setRecords] = useState('')
    const [errorCount, setErrorCount] = useState([])
    const [successCount, setSuccessCounts] = useState([])
    const [TrackingNumber, setTrackingNumber] = useState('')
    const [bulkstate, setBulkstate] = useState(false)
    const [Message, setMessage] = useState(false)

    let error = (errorCount.pop() + 1)
    let success = (successCount.pop() + 1)
    const navigate = useNavigate();
    const EXCEL_FILE_URL = `${CommonURL.replace("api", "")}ShipmentFormat.xlsx`;
    const DownloadFileAtURL = (url) => {
        const fileName = url.split('/').pop()
        const aTag = document.createElement('a');
        aTag.href = url
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    const readFile = async (e) => {
        const file = e.target.files[0]
        const data = await file.arrayBuffer(file)
        const excelFile = xlsx.read(data)
        const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]]
        const exceljson = xlsx.utils.sheet_to_json(excelSheet)
        setRecords(exceljson)
    }
    let errorCounts = [];
    let successCounts = [];
    let trackingNo = [];
    let message = [];
    const addShipments = async () => {
        setBulkstate(true)
        for (let i = 0; i < Records.length; i++) {
            const Token = cookies.get('Token')
            let ClientCode = cookies.get('clientCode');
            let data = ({ Token, ClientCode })
            let response = ({ ...data, ...Records[i] })
            await fetch(`${CommonURL}/ShipSagarApi/AddShipmentData`, {
                method: 'POST',
                body: JSON.stringify(response),
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(async (response) => {
                let res = await response.json();
                if (res.status === "Success") {
                    successCounts.push(i)
                } else if (res.status === 'ERROR') {
                    if (res.message === "EXPIRED") {
                        navigate("/login");
                    } else {
                        errorCounts.push(i)
                        trackingNo.push(Records[i].TrackingNo)
                        message.push(res.message)
                    }
                }
            })
        }
        setTrackingNumber(trackingNo.join().replaceAll(',', ' || '))
        setMessage(message.join())
        setBulkstate(false)
        setErrorCount(errorCounts)
        setSuccessCounts(successCounts)
    }


    return (
        <>
            <div className="modal fade" id="exampleModal1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-lg " >
                    <div className="modal-content px-3">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold" id="exampleModalLabel1">Upload Shipment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body" style={{ "paddingLeft": "50px" }} >
                                <div className="x_content form-horizontal form-label-left">
                                    <form id="demo-form2" data-parsley-validate="" className="form-horizontal form-label-left" noValidate="">
                                        <div className='row'>
                                            <div className='col-sm-7'>

                                                <div className="row">
                                                    <div className="col-sm-10">
                                                        <div className='col-md'>
                                                            <div className="form-group">
                                                                <h5><label className="col-md-12 col-sm-12 col-xs-12"><b>Note:</b></label></h5>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-md-12 col-sm-12 col-xs-12">1.Please Upload Only given File of Format</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-md-12 col-sm-12 col-xs-12">2.Below Given Fields and Header of Excel File Should Be Same</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><br />
                                                <div className="row">
                                                    <div className="col-md">
                                                        <div className="form-group">
                                                            <label htmlFor="control-label col-md">Choose File<span style={{ "color": "red" }} >*</span></label>
                                                            <div className="col-md">
                                                                <input name="file" onChange={(e) => readFile(e)} type="file" tabIndex={1} autoComplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='col-sm-10 mt-4'>
                                                        <textarea id="txtField " readOnly="readonly" value='CompanyName, CountryName, CourierCode, CustomerName, MobileNo, OrderNo, ShipmentType, TrackingNo, emailid' className="form-control col-sm-12" style={{ "height": "75%", fontSize: '14px' }} rows="3" name="HeaderField"></textarea>
                                                        <label className='fs-6'>{Records.length ? `Total Records : ${Records.length}` : ""} </label><br />
                                                        <label className='fs-6 text-success'>{success ? `Total Success Records : ${Records.length- error}` : ''} </label><br />
                                                        <label className='fs-6 text-danger'>{error ? `Total Failed Records : ${error}` : ''} </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-5 p-3 bg-light text-danger' style={{ "overflowY": "auto", "height": '300px' }}>
                                                <div className='text-dark fs-5 text-center'>Error Log</div>
                                                {
                                                    error ?
                                                        <>
                                                            <label ><b className='text-dark'>TrackingNumbers :-</b>  {TrackingNumber}</label>
                                                            <label ><b className='text-dark'> Error Message :-</b>  {Message}</label>
                                                        </> : ""
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <LoadingButton
                                loading={bulkstate}
                                size="small"
                                disabled={bulkstate}
                                loadingPosition='center'
                                tabIndex={1}
                                onClick={addShipments}
                                className='py-1'
                                style={{ background: "var(--mycolorBlue)" }}
                                variant="contained">
                                <span><RiUploadCloudFill className='shipment-icon' /></span> Upload Shipment
                            </LoadingButton>
                            <button onClick={() => DownloadFileAtURL(EXCEL_FILE_URL)} style={{ "background": "var(--mycolorOrange)" }} className="btn text-light" tabIndex="1"> <span><RiDownloadCloudFill className='shipment-icon' /></span>Download Format</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BulkAddShipments;

