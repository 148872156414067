import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { RiEdit2Fill, RiUploadCloudLine, RiUploadCloudFill, RiFilter3Fill, RiDownloadCloudFill } from "react-icons/ri";
import SideBar from '../SideBar';
import AddShipments from './AddShipments';
import BulkAddShipments from './BulkAddShipments';
import './ShipmentStyle.css';
import Pagination from '@mui/material/Pagination';
import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import { CommonURL } from "../../commoncomponents/components";
import { ExportPdf } from '../Exports/Export';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Shipments = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const [getRecords, setGetRecords] = useState([]);
    const [tracking, setTracking] = useState('');
    const [orderno, setOrderno] = useState('');
    const [status, setStatus] = useState('');
    const [carrier, setCarrier] = useState('');
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [totalRecord, setTotalRecord] = useState('');
    const [updateData, setUpdateData] = useState('');
    const [pageSize, setPageSize] = useState('');
    const [DateFrom, setDateFrom] = useState(currentDate);
    const [DateTo, setDateTo] = useState(new Date());
    const [StatusArr, setStatusArr] = useState('');
    const [CourierDrop, setCourierDrop] = useState([]);
    const [StatusDrop, setStatusDrop] = useState([]);
    const [CourierArr, setCourierArr] = useState('');
    const [hfCourier, setHfCourier] = useState('');
    const [hfStatus, setHfStatus] = useState('');
    const [filterstate, setFilterstate] = useState(false);
    const navigate = useNavigate();
    const XLSX = require('xlsx');
    const url = window.location.href;
    let courier = url.split('#')[1];
    let urlStatus = url.split('#')[2];
    let urlFromDate = url.split('#')[3];
    let urlToDate = url.split('#')[4];
    let newArrStatusdrop = [];
    let newArrStatus = [];
    let arrayStatus = (StatusArr.split(','));
    let arrayCourier = (CourierArr.split(','));
    let noOfPage = Math.ceil(totalRecord / pageSize);
    let datefrom = new Date(urlFromDate);
    let dateto = new Date(urlToDate);

    for (let i = 0; i < StatusDrop.length; i++) {
        let newArrayStatus = (StatusDrop[i].statusName)
        newArrStatusdrop.push(newArrayStatus)
    };

    for (let i = 0; i < arrayStatus.length; i++) {
        let newArrayStatus = (arrayStatus[i].split('~'))
        let data = (newArrayStatus[0] + "~" + newArrayStatus[1])
        newArrStatus.push(data)
    };
    function dataForFilter(a) {
        setUpdateData(getRecords[a])
    }

    const handleDateChangeFrom = (date) => {
        setDateFrom(date);
    };

    const handleDateChangeTo = (date) => {
        setDateTo(date);
    };

    const handleChange = (event, value) => {
        getAllRecords(hfCourier, hfStatus, value);
    };

    const filterRecord = () => {
        getAllRecords()
    };

    const courierFilter = (CarrierCode) => {
        setHfCourier(CarrierCode);
        getAllRecords(CarrierCode, "")
    };

    const statusFilter = (Status) => {

        setHfStatus(Status);
        getAllRecords(hfCourier, Status)
    };

    const getStatusList = async () => {
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        const data = { clientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetStatus`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                const data = res.getStatusList;
                setStatusDrop(data)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    const getCourierList = async () => {
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        const data = { clientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetCourierList`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                const data = res.getCourierList;
                setCourierDrop(data)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    const getAllRecords = async (CarrierCode, Status, CurrentPage) => {
        setFilterstate(true)
        let clientCode = cookies.get('clientCode');
        let Token = cookies.get('Token')
        let TrackingNo = tracking;
        let CurrentStatus = status;
        let CourierCode = carrier;
        const IsExport = false
        let pageNo = 1;
        if (CarrierCode !== undefined && CarrierCode !== "") {
            CourierCode = CarrierCode.split('(')[0];
        }
        if (Status !== undefined && Status !== "") {
            CurrentStatus = Status.split('(')[0];
        }
        if (CurrentPage !== undefined) {
            pageNo = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const data = `{"clientCode": "${clientCode}","Token": "${Token}" ,"DateFrom":"${document.getElementById("dateFrom").value}" ,"DateTo":"${document.getElementById("dateTo").value}" ,"TrackingNo":"${TrackingNo}","CurrentStatus":"${CurrentStatus}","CourierCode":"${CourierCode}" ,"orderno":"${orderno}","pageNo": ${pageNo} ,"IsExport":${IsExport}}`;

        await fetch(`${CommonURL}/ShipSagarApi/GetFilterShipmentRecord`, {
            method: 'POST',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setFilterstate(false)
                const data = res.filterShipment;
                const totalRecord = res.totalRecord;
                const pageSize = res.pageSize;
                setTotalRecord(totalRecord);
                setPageSize(pageSize);
                setStatusArr(res.statusArr);
                setCourierArr(res.courierArr);
                setGetRecords(data);
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setFilterstate(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                    setGetRecords([]);
                    setTotalRecord(0);
                    setPageSize(1);
                    setStatusArr("");
                    setCourierArr("");
                }
            }
        }).catch((err) => {
            setFilterstate(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    };

    const handleDownloadpdf = async () => {
        const apiUrl = `${CommonURL}/ShipSagarApi/GetFilterShipmentRecord`;
        const requestBody = {
            clientCode: cookies.get('clientCode'),
            Token: cookies.get('Token'),
            DateFrom: `${document.getElementById("dateFrom").value}`, DateTo: `${document.getElementById("dateTo").value}`, TrackingNo: `${tracking}`, CurrentStatus: `${status}`, CourierCode: `${carrier}`, OrderNo: `${orderno}`, PageNo: 1, IsExport: true,
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        const records = await ExportPdf(apiUrl, requestBody, requestHeaders);
        if (records) {
            downloadPDF(records);
        }
    };

    const downloadPDF = (records) => {
        const doc = new jsPDF({ orientation: 'landscape' });
        const tableData = records.map((record) => [
            record.courierCode, record.trackingNo, record.customerName, record.emailID, record.mobileNo, record.currentStatus, record.shipmentType, record.countryName, record.companyName, record.createdDate,
        ]);
        doc.autoTable({
            head: [
                ['Courier Code', 'Tracking Number', 'Customer Name', 'Email ID', 'Mobile Number', 'Current Status', 'Shipment Type', 'Country Name', 'Company Name', 'Date Added',
                ],
            ],
            body: tableData,
        });
        doc.save('AllRecords.pdf');
    };

    const handleDownloadExcel = async () => {
        const apiUrl = `${CommonURL}/ShipSagarApi/GetFilterShipmentRecord`;
        const requestBody = {
            clientCode: cookies.get('clientCode'),
            Token: cookies.get('Token'),
            DateFrom: `${document.getElementById("dateFrom").value}`, DateTo: `${document.getElementById("dateTo").value}`, TrackingNo: `${tracking}`, CurrentStatus: `${status}`, CourierCode: `${carrier}`, OrderNo: `${orderno}`, PageNo: 1, IsExport: true,
        };
        const requestHeaders = {
            'Content-Type': 'application/json'
        };
        const records = await ExportPdf(apiUrl, requestBody, requestHeaders);
        if (records) {
            downloadExcel(records);
        }
    };

    const downloadExcel = (records) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Courier Code', 'Tracking Number', 'Customer Name', 'Email ID', 'Mobile Number', 'Current Status', 'Shipment Type', 'Country Name', 'Company Name', 'Date Added',

            ],
            ...records.map((record) => [record.courierCode, record.trackingNo, record.customerName, record.emailID, record.mobileNo, record.currentStatus, record.shipmentType, record.countryName, record.companyName, record.createdDate,

            ]),
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveExcelFile(excelBuffer, 'AllRecords.xlsx');
    };

    const saveExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    useEffect(() => {
        if (urlStatus === undefined) {
            getAllRecords();
            getCourierList();
            getStatusList();
        }
    }, []);

    useEffect(() => {
        if (urlStatus !== undefined) {
            setCarrier(courier.replace("%20", " "))
            setStatus(urlStatus.replace("%20", " "))
            setDateFrom(datefrom)
            setDateTo(dateto)
            getAllRecords();
        }
    }, [status, carrier])

    return (
        <>
            <SideBar>
                <div className='container-fluid' data-aos="fade-down" data-aos-duration="2000">
                    <h6 className='fw-bold' style={{ color: '#194c63' }}>Shipments</h6>
                    <div className='row bg-light p-2 rounded-top justify-content-between upload-section'>
                        <div className='col-sm-6 text-title'></div>
                        <div className='col-sm-6 d-flex justify-content-between .flex-sm-column upload-btn' style={{ maxWidth: "fit-content" }}>
                            <button className='btn upload1 text-light me-2 d-flex gap-2 justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{
                                "background": "var(--mycolorBlue)"
                            }}>
                                <span><RiUploadCloudFill className='shipment-icon' /></span>
                                <span className='text-center fw-normal '>Bulk Upload Shipments</span>
                            </button>
                            <button className='btn upload1 text-light me-2 d-flex gap-2 justify-content-center align-items-center'
                                data-bs-toggle="modal" data-bs-target="#exampleModal" style={{
                                    "background": "var(--mycolorBlue)"
                                }}>
                                <span><RiUploadCloudLine className='shipment-icon pe-2' /></span>
                                <span className='text-center fw-normal '>Add Shipment</span>
                            </button>
                        </div>
                    </div>
                    <div className='row bg-light d-flex  p-3'>
                        <div className='col-sm-10 '>
                            <div className="row">
                                <div className='col-md-2 d-flex flex-column gap-1 '>
                                    <label>Date From</label>
                                    <DatePicker id="dateFrom" className='form-control input-fields' dateFormat="dd-MMM-yyyy" selected={DateFrom} onChange={handleDateChangeFrom} />
                                </div>
                                <div className='col-md-2 d-flex flex-column gap-1 ' >
                                    <label>Date To</label>
                                    <DatePicker id="dateTo" className='form-control input-fields' dateFormat="dd-MMM-yyyy" selected={DateTo} onChange={handleDateChangeTo} />
                                </div>
                                <InputControl lableText='Tracking No.' maxLength='30' type='text' value={tracking} placeholder='Enter Tracking No.' onChange={(e) => setTracking(e.target.value)} />
                                <InputControl lableText='Order No.' type='text' maxLength="25" value={orderno} placeholder='Enter Order No.' onChange={(e) => setOrderno(e.target.value)} />
                                <div className='col-md-2 d-flex flex-column gap-1' >
                                    <label>Status</label>
                                    <Autocomplete
                                        inputValue={status}
                                        onInputChange={(event, newInputValue) => {
                                            setStatus(newInputValue);
                                        }}
                                        disablePortal
                                        id="combo-box-demo"
                                        freeSolo
                                        options={newArrStatusdrop}
                                        className="mb-3"
                                        renderInput={(params) => <TextField className='form-control' placeholder="Enter Status" {...params} />}
                                    />
                                </div>
                                <div className='col-md-2 d-flex flex-column gap-1 ' >
                                    <label>Courier</label>
                                    <select className='form-control' value={carrier} onChange={(e) => setCarrier(e.target.value)} style={{ "WebkitAppearance": "push-button", 'fontSize': "12px" }}>
                                        <option hidden>--Choose Courier---</option>
                                        {
                                            CourierDrop.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.courierCode}>{item.courierName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2  text-center  filter' >
                            <LoadingButton
                                loading={filterstate}
                                size="small"
                                disabled={filterstate}
                                loadingPosition='center'
                                tabIndex={1}
                                onClick={filterRecord}
                                className='py-1 form-control w-75'
                                style={{ background: "var(--mycolorOrange)" }}
                                variant="contained">
                                <span><RiFilter3Fill className='filter-icon' /></span>Apply
                            </LoadingButton>
                        </div>
                    </div>
                    <div className='row bg-light px-3 py-1 '>
                        <div className='col-sm-12'>
                            <div className='pb-3'>
                                <h6 className='fw-bold m-0' style={{ "fontFamily": "Calibri", "letterSpacing": "-.8px" }}>Carrier</h6>
                                <button className='btn btn-sm text-light current m-1' style={{ "background": "var(--mycolorBlue)" }} onClick={() => courierFilter("")}>All Records</button>
                                {
                                    arrayCourier.map((item, index) => {
                                        if (getRecords.length > 0) {
                                            return (
                                                <span key={index}>
                                                    <button className='btn m-1 p-1' onClick={() => courierFilter(item.split('~')[0])} style={{ "color": "black", "border": "1px solid rgb(40, 45, 87)" }} ><img src={item.split('~')[1]} height='18px' alt='' /> {item.split('~')[0]}</button>
                                                </span>
                                            )
                                        } else { return ("") }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row bg-light px-3 py-1 '>
                        <div className='col-sm-12'>
                            <div className='pb-3'>
                                <h6 className='fw-bold m-0' style={{ "fontFamily": "Calibri", "letterSpacing": "-.8px" }}>Current Status</h6>
                                <button className='btn btn-sm text-light current m-1' style={{ "background": "var(--mycolorBlue)" }} onClick={() => statusFilter("")}>All Records</button>
                                {
                                    newArrStatus.map((item, index) => {
                                        if (getRecords.length > 0) {
                                            return (
                                                <span key={index}>
                                                    <button className='btn m-1 p-1' onClick={() => statusFilter(item.split('~')[0])} style={{ "color": item.split('~')[1], "border": "1px solid" + item.split('~')[1] }} ><img src={item.split('~')[1]} height='18px' alt='' /> {item.split('~')[0]}</button>
                                                </span>
                                            )
                                        } else { return ("") }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row bg-light  rounded-bottom  pt-3' >
                        <div className='col-sm-12' style={{ "overflowX": "auto" }}>
                            <div className='pb-3 row table-btn' style={{ "display": "flex" }}>
                                <div className='col-sm-4'><span className='m-0 ps-2' style={{ "fontFamily": "Calibri", "letterSpacing": ".8px", "fontSize": "17px" }}>Total : <b>{totalRecord} Shipments</b></span></div>
                                <div className="col-sm-8 d-flex justify-content-end">
                                    <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
                                        <button className="text-light btn"
                                            style={{ background: "var(--mycolorBlue)", float: "right" }} >
                                            <span>
                                                <RiDownloadCloudFill className="shipment-icon" />
                                            </span>
                                            Download Shipment
                                        </button>
                                        <div className="dropdown-content con" >
                                            <a onClick={handleDownloadExcel}>EXCEL</a>
                                            <a onClick={handleDownloadpdf}>PDF</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className='table  text-left table-hover' style={{ "fontSize": "12px" }} id="download">
                                <thead style={{ "background": "rgb(231 242 249)","fontSize": "13px",borderTop: "1px solid #efefef",borderBottom: "2px solid #f1f1f1"}}>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Tracking Number</th>
                                        <th>Current Status</th>
                                        <th>Customer</th>
                                        <th>Shipment Type</th>
                                        <th>Country Name</th>
                                        <th>Company Name</th>
                                        <th>Entry Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getRecords.map((item, index) =>
                                            <tr key={index}>
                                                <td style={{fontSize : "13px"} } >{item.orderNo}<br /><RiEdit2Fill className='fs-6 text-danger pointer' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => dataForFilter(index)} /></td>
                                                <td className=" fw-bold" style={{fontSize : "13px"} }><Link to={'/tracking/#' + item.trackingNo} style={{ "textDecoration": "none", "color": "black" }} rel='noreferrer' target='_blank'><img src={item.logoURL} height='30px' width='30px' alt='..' style={{border: "1px solid #d7d7d7",borderRadius: "4px"}} />{item.courierCode}<br /><span className='pointer'>{item.trackingNo}</span></Link></td>
                                                <td ><button className='btn w-100 p-1' style={{
                                                    "background": item.statusColor,
                                                     borderRadius: "0px", "color": "white"
                                                }}>{item.currentStatus}</button></td>
                                                <td>{item.customerName}<br />{item.emailID}<br />{item.mobileNo}</td>
                                                <td>{item.shipmentType}</td>
                                                <td>{item.countryName}</td>
                                                <td>{item.companyName}</td>
                                                <td>{item.createdDate}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="row">
                                <div className='col-sm-12 pb-2'>
                                    <Stack spacing={2}>
                                        <Pagination count={noOfPage} color="primary" page={currentPageNo} onChange={handleChange} />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AddShipments data={updateData} />
                <BulkAddShipments />
            </SideBar>
        </>
    )
}
export default Shipments;
export const InputControl = ({ defaultValue, value, onChange, lableText, type, placeholder, maxLength }) => {
    return (
        <>
            <div className='col-md-2 d-flex flex-column gap-1 ' >
                <label>{lableText}</label>
                <input type={type} value={value} maxLength={maxLength} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} className='form-control input-fields' />
            </div>
        </>
    )
}