import { Timeline } from 'antd';
import { CommonURL } from "../../commoncomponents/components"
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Tracking = () => {
    const navigate = useNavigate();
    const [TrackingData, setTrackingData] = useState([])
    const [TrackingNo, setTrackingNo] = useState('')
    var data = [];
    for (let i = TrackingData.length-1; i >=0; i--) {
        data.push({
            label: <p>{TrackingData[i].ActionDate}<br /><i className='text-secondary'>{TrackingData[i].ActionTime}</i></p>,
            children: <p>{TrackingData[i].ActionDescription} <br /><i className='text-secondary'>{TrackingData[i].ActionLocation}</i></p>
        })
    }

    const Tracking = async () => {
        const url = window.location.href;
        const Token = cookies.get('Token')
        let ClientCode = cookies.get('clientCode')
        let TrackingNo = url.split('#')[1]; 
        setTrackingNo(TrackingNo);
        const data = { ClientCode, Token, TrackingNo }
        await fetch(`${CommonURL}/ShipSagarApi/ShipmentTracking`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            let data = (JSON.parse(res.trackingDetails));          
            let data1 = (JSON.parse(data));
            let TrackingDetails = data1.TrackingHistory;
            setTrackingData(TrackingDetails)
            if (res.status === "SUCCESS") {              
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    useEffect(() => {
        Tracking();     
    }, [])


    return (
        <>
            <div className='container-fluid bg-light'>
                <div className='row'>
                    <div className='col-sm-8 text-center'>
                        <span className='fs-4'>Tracking History {TrackingNo}</span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-8 pt-5'>    
                       
                        <Timeline
                            mode='left'                          
                            items={data}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};
export default Tracking;