import Sidebar from '../SideBar';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import copy from "copy-to-clipboard";
import { CommonURL } from "../../commoncomponents/components";
import Payment from '../Payment/Payment'
import { FileUploader } from "react-drag-drop-files";
import './Profile.css';
import { FaSave, FaCopy } from 'react-icons/fa';
import { LoadingButton } from '@mui/lab';
import { BiReset } from 'react-icons/bi';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Profile = () => {
    let [profile, setProfile] = useState([])
    const [OldPassword, setOldPassword] = useState('')
    const [NewPassword, setNewPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [WebHookURL, setWebHookURL] = useState('')
    const [ClientName, setClientName] = useState('')
    const [MobileNo, setMobileNo] = useState('')
    const [WebsiteURL, setWebsiteURL] = useState('');
    const [Address, setAddress] = useState('');
    let [ClientLogo, setImageFile] = useState('');
    let [Status, setStatus] = useState('');
    let [state, setState] = useState(false);
    let [passstate, setPassState] = useState(false);
    let [updatestate, setUpdatestate] = useState(false);
    let [updateurlstate, setUpdateurlstate] = useState(false);
    let [Color, setColor] = useState('');
    let ClientCode = cookies.get('clientCode');
    let Token = cookies.get('Token');
    const [image, setImage] = useState(null)
    const navigate = useNavigate();
    const handleChange = (file) => {
        setImage(URL.createObjectURL(file))
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                setImageFile(reader.result);
            };
        }
    };

    const copyToClipboard = () => {
        copy(profile.apiToken);
        toast.success(`Api Key copied`, {
            position: 'top-center'
        })
    }

    var WebhookDoc = String.raw`
         {
            "ClientCode":"C1000", //string of max length 10
            "TrackingNo":"X43235768", //string of max length 25
            "CourierCode":"DTDC", //string of max length 10
            "TrackingHistory":[
              {
                 "ActionDate":"25-May-2023", //Activity Date Max Length 15
                 "ActionTime":"2:12", //Activity Time Max Length 15
                 "ActionLocation":"First Mile", //Activity Location
                 "ActionDescription":"Softdata Upload" //Activity of shipment
              },
              {
                 "ActionDate":"26-May-2023",
                 "ActionTime":"1:23",
                 "ActionLocation":"Panipat Branch(TEHSIL CAMP (NEW))",
                 "ActionDescription":"Out For Delivery"
              },
              {
                 "ActionDate":"26-May-2023",
                 "ActionTime":"6:10",
                 "ActionLocation":"Panipat Branch",
                 "ActionDescription":"Successfully Delivered"
              }
           ]
        }
        `;
    const getProfile = async () => {
        let data = { ClientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/GetProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setProfile(res);
                setClientName(res.clientName);
                setMobileNo(res.mobileNo);
                setWebsiteURL(res.website);
                setAddress(res.address);
                setWebHookURL(res.webhookURL);
                setImageFile(res.clientLogo)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    const ChangePassword = async () => {
        setPassState(true)
        let data = { OldPassword, NewPassword, ConfirmPassword, ClientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/ChangePassword`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setPassState(false)
                toast.success(res.message, {
                    position: 'top-center'
                })
                setOldPassword('')
                setNewPassword('')
                setConfirmPassword('')
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setPassState(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            setPassState(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    const WebHookURLUpdate = async () => {
        setUpdateurlstate(true)
        let data = { WebHookURL, ClientCode, Token }
        await fetch(`${CommonURL}/ShipSagarApi/UpdateWebHook`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'Success.') {
                setUpdateurlstate(false)
                toast.success(res.message, {
                    position: 'top-center'
                })
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setUpdateurlstate(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            setUpdateurlstate(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    const updateProfile = async () => {
        setUpdatestate(true)
        const emailID = ''
        let data = { ClientCode, Token, ClientName, MobileNo, Address, ClientLogo, WebsiteURL, emailID }
        await fetch(`${CommonURL}/ShipSagarApi/UpdateProfile`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'Success.') {
                setUpdatestate(false)
                getProfile();
                setImage('')
                toast.success(res.message, {
                    position: 'top-center'
                })
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setUpdatestate(false)
                    toast.error(res.message, {
                        position: 'top-center'
                    })
                }
            }
        }).catch((err) => {
            setUpdatestate(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    const TestWebhook = async () => {
        let data = { ClientCode, Token,WebHookURL }
        setState(true)
        await fetch(`${CommonURL}/ShipSagarApi/CheckWebhookURL`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'SUCCESS') {
                setStatus('Successfully delivered Webhook url')
                setColor('green')
                setState(false)
            } else if (res.status === 'ERROR') {
                if (res.message === "EXPIRED") {
                    navigate("/login");
                } else {
                    setStatus(res.message)
                    setColor('red')
                    setState(false)
                }
            }
        }).catch((err) => {
            setState(false)
            toast.error(err.message, {
                position: 'top-center'
            })
        });
    }

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <div className='vh-100'>
            <Sidebar>
                <div className="container-fluid" data-aos="fade-down" data-aos-duration="2000">
                    <div className="row" style={{ "padding": "0px 12px" }} >
                        <div className="col-lg-12 main-Head">
                            <div className="page-title-box p-1">
                                <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>Client Profile</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-12"  >
                            <div className="card">
                                <div className="card-body">
                                    <div className='row py-2 px-3'>
                                        <div className="col-lg-5 col-md-5 col-sm-5 mx-auto pt-2">
                                            <div className="col-lg-12 col-md-12 col-sm-12  ">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 ">Client Code :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <span className='text-mycolor'>{profile.clientCode}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 ">Email ID :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <span className='text-mycolor '>{profile.emailID}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 ">API Key :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <span className='text-mycolor'>{profile.apiToken}</span> <span onClick={copyToClipboard} className='pointer' title='Copy'><FaCopy className='fs-3 border border-2 p-1 rounded' style={{ 'color': "#b84569", 'border': '0px solid !important' }} /></span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3">Tracking Cost :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <span className='text-mycolor'>₹{profile.perTrackingCost}/- Per shipment</span>
                                                            <Payment />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 pt-1">Client Name :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <input type="text" maxLength="25" defaultValue={profile.clientName} onChange={(e) => setClientName(e.target.value)} className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 mx-auto ">
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 pt-1">Mobile No :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <input type="text" maxLength="15" defaultValue={profile.mobileNo} onChange={(e) => setMobileNo(e.target.value)} className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 pt-1">Website :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <input type="text" defaultValue={profile.website} onChange={(e) => setWebsiteURL(e.target.value)} className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 pt-1">Address :</label>
                                                        <div className="col-md-9 col-sm-11">
                                                            <textarea defaultValue={profile.address} maxLength='250' onChange={(e) => setAddress(e.target.value)} className="form-control" ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-3 pt-1">Upload Logo :</label>
                                                        <div className="col-md-7 col-sm-11">
                                                            <FileUploader handleChange={handleChange} name="file" />
                                                        </div>
                                                        <div className="col-md-2 ">
                                                            <div className='box p-1'>
                                                                <img src={profile.clientLogo} alt='...' height='40px' width='60px' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-sm-12'>
                                            <div className='col-sm-6 mx-auto d-flex flex-wrap gap-2 justify-content-center text-center pt-3'>                                               
                                                <LoadingButton
                                                    loading={updatestate}
                                                    size="small"
                                                    disabled={updatestate}
                                                    loadingPosition='center'
                                                    tabIndex={1}
                                                    onClick={updateProfile}
                                                    className='py-1'
                                                    style={{ background: "var(--mycolorOrange)" }}
                                                    variant="contained">
                                                    <FaSave /> Submit & Save
                                                </LoadingButton>                     
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ "padding": "0px 12px" }} >
                        <div className="col-lg-12 main-Head">
                            <div className="page-title-box p-1">
                                <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>Change Password</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-12 "  >
                            <div className="card">
                                <div className="card-body">
                                    <div className="row pt-4">
                                        <div className="col-lg-5 col-md-5 col-sm-5 mx-auto">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-4 col-form-label" htmlFor="txtOldPassword">Old Password<span className="mandatory">*</span></label>
                                                        <div className="col-md-8 col-sm-11">
                                                            <input type="password" value={OldPassword}  onChange={(e) => setOldPassword(e.target.value)} placeholder="Old Password" className="form-control" maxLength="100" tabIndex={1} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-4 col-form-label" htmlFor="txtNewPassword">New Password<span className="mandatory">*</span></label>
                                                        <div className="col-md-8 col-sm-11">
                                                            <input type="password" value={NewPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" className="form-control" maxLength="100" tabIndex={1} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-4 col-form-label" htmlFor="txtConfirmPassword">Confirm Password<span className="mandatory">*</span></label>
                                                        <div className="col-md-8 col-sm-11">
                                                            <input type="password" value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" className="form-control" maxLength="100" tabIndex={1} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 my-3" >
                                                <div className="d-flex flex-wrap gap-2 justify-content-center">                           
                                                    <LoadingButton
                                                        loading={passstate}
                                                        size="small"
                                                        disabled={passstate}
                                                        loadingPosition='center'
                                                        tabIndex={1}
                                                        onClick={ChangePassword}
                                                        className='py-1'
                                                        style={{ background: "var(--mycolorBlue)" }}
                                                        variant="contained">
                                                        <FaSave /> Change Password
                                                    </LoadingButton>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="x_panel">
                                                <div className="x_title">
                                                    <h2>Password Policy</h2>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="x_content">
                                                    <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="form-group ps-3">
                                                            <li>White spaces are not allowed.</li>
                                                            <li>Password must be between 8 to 15 character(s).</li>
                                                            <li>Password must be combination of atleast one numeric and one upper case <br />letter and Special character [@@$#!() ], white spaces are not allowed.</li>
                                                            <li>New password and confirm password must be same.</li>
                                                            <li>Old password and New password should be different.</li>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ "padding": "0px 12px" }} >
                        <div className="col-lg-12 main-Head">
                            <div className="page-title-box p-1">
                                <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>Webhook Setting</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-lg-8 col-md-8 col-sm-8 mx-auto">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="mt-1">
                                                    <div className="row">
                                                        <label className="col-md-2 col-sm-2 col-form-label pt-3">Webhook URL<span className="mandatory">*</span></label>
                                                        <div className="col-md-5 col-sm-5 pt-3">
                                                            <input type="text" maxLength="500" defaultValue={profile.webhookURL} onChange={(e) => setWebHookURL(e.target.value)} className="form-control" />
                                                            <span style={{ 'color': Color }}>{Status}</span>
                                                        </div>
                                                        <div className="col-md-5 col-sm-5 pt-3">

                                                            <LoadingButton loading={state} disabled={state}
                                                                loadingPosition='center'
                                                                size="small"
                                                                onClick={TestWebhook}
                                                                style={{ background: "var(--mycolorBlue)" }}
                                                                className='me-2 py-1'
                                                                variant="contained">
                                                                Test Webhook
                                                            </LoadingButton>
                                                            <LoadingButton loading={updateurlstate} disabled={updateurlstate}
                                                                loadingPosition='center'
                                                                size="small"
                                                                onClick={WebHookURLUpdate}
                                                                style={{ background: "var(--mycolorOrange)" }}
                                                                className='me-2 py-1'
                                                                variant="contained">
                                                                <FaSave /> Update
                                                            </LoadingButton>                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 mx-auto"></div>
                                    </div>
                                    <div className='row pt-5'>
                                        <div className='row ms-3'>
                                            <h4>Sample request string of webhook API (Method POST)</h4>
                                        </div>
                                        <div className='col-sm-12 mx-auto'>
                                            <pre>
                                                {WebhookDoc}
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Sidebar>
        </div>
    )
}
export default Profile;