import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar';
import { CommonURL } from "../../commoncomponents/components";
import { Cookies } from 'react-cookie';
import { RiDownloadCloudFill } from "react-icons/ri";
import { AiFillBackward } from "react-icons/ai";
import * as XLSX from 'xlsx';
import { ExportCourier } from '../Exports/Export';
import { useNavigate } from "react-router-dom";
const cookies = new Cookies();


const CourierList = () => {
    const navigate = useNavigate();
    const [courier, setCourier] = useState([]);

    const Courier = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token };

        await fetch(`${CommonURL}/ShipSagarApi/GetCourierList`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            let res = await response.json();

            setCourier(res);
            })
            .catch((err) => {
            });
    };
    var a = courier.getCourierList;
    useEffect(() => {
        Courier();
    }, []);
 
    const handleDownloadExcel = async () => {
        const apiUrl = `${CommonURL}/ShipSagarApi/GetCourierList`;
        const requestBody = {
            clientCode: cookies.get('clientCode'),
            Token: cookies.get('Token')
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        const records = await ExportCourier(apiUrl, requestBody, requestHeaders);
        if (records) {
            downloadExcel(records);
        }
    };
    const downloadExcel = (records) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            [
               'courierName','logoURL'
            ],
            ...records.map((record) => [record.courierName, record.logoURL,
            ]),
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveExcelFile(excelBuffer, 'AllRecords.xlsx');
    };

    const saveExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    const GoBack = () => {
        navigate('/Settings')
    }


    return (
        <>
            <Sidebar>
                <div className='container-fluid' data-aos="fade-down" data-aos-duration="2000" >
                    <div className="row">
                        <div className="col-sm-6"> <h6 className='fw-bold' style={{ color: '#194c63' }}>Courier List</h6>
                                <button
                                    type="button"
                                    style={{ background: "#0d8787" }}
                                    className="btn text-light"
                                    onClick={GoBack}>
                                    <AiFillBackward />Back
                                </button>
                        </div>
                        <div className="col-sm-6 ">
                            <button className='text-light btn download-courier' style={{ background: "rgb(40, 45, 87)",float:"right" }}>
                                <span><RiDownloadCloudFill className='shipment-icon' /></span>
                                <span className='text-center fw-normal' onClick={handleDownloadExcel }>Download Courier</span>
                            </button>
                        </div>
                    </div>
                    <div className='row bg-light rounded-top shadow d-flex p-3 pb-0'>
                        {Array.isArray(a) && a.length > 0 ? (
                            a.map((item) => (
                                <div className="col-md-2 mb-4" >
                                    <div className="card card-radius text-center pt-3 shadow  h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2" style={{ color: "black" }}>
                                                    <div className="mb-0"><b>{item.courierName}</b></div>
                                                </div>
                                                <div>
                                                    <img src={item.logoURL} height="120px" width="120px" alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))
                        ) : (
                            <div>No courier data available.</div>
                        )}   
                    </div>
                   </div>
            </Sidebar>
        </>
   )
}
export default CourierList;