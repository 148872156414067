import Sidebar from '../SideBar';
import { RiFilter3Fill, RiDownloadCloudFill } from "react-icons/ri";
import { CommonURL } from "../../commoncomponents/components";
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { ExportCarrierPerformance } from '../Exports/Export';
import './CarrierPerformance.css';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const CarrierPerformance = () => {
    let [state, setState] = useState(false);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const [reportData, setReportData] = useState([]);
    const [FromDate, setDateFrom] = useState(currentDate);
    const [ToDate, setDateTo] = useState(new Date());
    
    const navigate = useNavigate()

    let urldatefrom = FromDate.toLocaleDateString('en-US')
    let urldateto = ToDate.toLocaleDateString('en-US')

    const handleDateChangeTo = (date) => {
        setDateTo(date);
    };

    const handleDateChange = (date) => {
        setDateFrom(date);
    };

    const getReport = async () => {
        setState(true)
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = `{"ClientCode":"${ClientCode}","Token":"${Token}" ,"FromDate":"${document.getElementById("dateFrom").value}" ,"ToDate":" ${document.getElementById("dateTo").value} "}`;
        await fetch(`${CommonURL}/Report/GetCourierPerformanceReport`, {
            method: 'POST',
            body: data,
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(async (response) => {
                let res = await response.json();
                if (res.status === "SUCCESS") {
                    setState(false)
                    let Totalorder = res?.getReport?.reduce((accumulator, report) => accumulator + Number(report.totalOrder.split('(')[0]), 0);
                    let TotalDelivered = res?.getReport?.reduce((accumulator, report) => accumulator + Number(report.delivered.split('(')[0]), 0);
                    let TotalRto = res?.getReport?.reduce((accumulator, report) => accumulator + Number(report.rto.split('(')[0]), 0);
                    let TotalUndeliverd = res?.getReport?.reduce((accumulator, report) => accumulator + Number(report.undelivered.split('(')[0]), 0);
                    let TotalIntransit = res?.getReport?.reduce((accumulator, report) => accumulator + Number(report.intransit.split('(')[0]), 0)
                   
                    let TotalDeliveredper = (((TotalDelivered) / Totalorder) * 100).toFixed(2)
                    let TotalRtoPer = (((TotalRto) / Totalorder) * 100).toFixed(2)
                    let TotalUndeliverdPer = (((TotalUndeliverd) / Totalorder) * 100).toFixed(2)
                    let TotalIntransitPer = (((TotalIntransit) / Totalorder) * 100).toFixed(2)
                  let img = './img/Total.png'
                    let array = {
                        logoURL: img, courierName: `Total `, totalOrder: Totalorder, delivered: `${TotalDelivered} (${TotalDeliveredper}%)`, rto: `${TotalRto} (${TotalRtoPer}%)`, undelivered: `${TotalUndeliverd} (${TotalUndeliverdPer}%)`, intransit: `${TotalIntransit} (${TotalIntransitPer}%)`
}
                    setReportData([array, ...res.getReport])

                } else if (res.status === 'ERROR') {
                    setReportData([])
                    if (res.message === "EXPIRED") {
                        navigate("/login");
                    } else {
                        setState(false)
                        toast.error(res.message, {
                            position: 'top-center'
                        })
                    }
                }

            })
            .catch((err) => {
                setState(false)
                toast.error(err.message, {
                    position: 'top-center'
                })
            });
    };

    useEffect(() => {
        getReport();
    }, []);

    const handleDownloadpdf = async () => {
        const apiUrl = `${CommonURL}/Report/GetCourierPerformanceReport`;
        const requestBody = {
            Token: cookies.get('Token'),
            clientCode: cookies.get('clientCode'),
            FromDate: document.getElementById("dateFrom").value,
            ToDate: document.getElementById("dateTo").value,
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        let records = await ExportCarrierPerformance(apiUrl, requestBody, requestHeaders);
        let Totalorder = records?.reduce((accumulator, report) => accumulator + Number(report.totalOrder.split('(')[0]), 0);
        let TotalDelivered = records?.reduce((accumulator, report) => accumulator + Number(report.delivered.split('(')[0]), 0);
        let TotalRto = records?.reduce((accumulator, report) => accumulator + Number(report.rto.split('(')[0]), 0);
        let TotalUndeliverd = records?.reduce((accumulator, report) => accumulator + Number(report.undelivered.split('(')[0]), 0);
        let TotalIntransit = records?.reduce((accumulator, report) => accumulator + Number(report.intransit.split('(')[0]), 0)
        let TotalDeliveredper = (((TotalDelivered) / Totalorder) * 100).toFixed(2)
        let TotalRtoPer = (((TotalRto) / Totalorder) * 100).toFixed(2)
        let TotalUndeliverdPer = (((TotalUndeliverd) / Totalorder) * 100).toFixed(2)
        let TotalIntransitPer = (((TotalIntransit) / Totalorder) * 100).toFixed(2)
        let img = './img/Total.png'
        let array = {
            logoURL: img, courierName: `Total `, totalOrder: Totalorder, delivered: `${TotalDelivered} (${TotalDeliveredper}%)`, rto: `${TotalRto} (${TotalRtoPer}%)`, undelivered: `${TotalUndeliverd} (${TotalUndeliverdPer}%)`, intransit: `${TotalIntransit} (${TotalIntransitPer}%)`
        }
        records = [array,...records]
        if (records) {
            downloadPDF(records);
        }
    };

    const downloadPDF = (records) => {
        const doc = new jsPDF({ orientation: 'landscape' });
        const tableData = records.map((record) => [
            record.courierName , record.totalOrder, record.delivered, record.rto, record.undelivered, record.intransit,
        ]);
        doc.autoTable({
            head: [
                ['Carrier name', 'Total Order', 'Delivered (%)', 'RTO (%)', 'Undelivered (%)', 'Intransit (%)'
                ],
            ],
            body: tableData,
        });
        doc.save('AllRecords.pdf');
    };

    const handleDownloadExcel = async () => {
        const apiUrl = `${CommonURL}/Report/GetCourierPerformanceReport`;
        const requestBody = {
            Token: cookies.get('Token'),
            clientCode: cookies.get('clientCode'),
            FromDate: document.getElementById("dateFrom").value,
            ToDate: document.getElementById("dateTo").value,
        };
        const requestHeaders = {
            'Content-Type': 'application/json',
        };
        let records = await ExportCarrierPerformance(apiUrl, requestBody, requestHeaders);
        let Totalorder = records?.reduce((accumulator, report) => accumulator + Number(report.totalOrder.split('(')[0]), 0);
        let TotalDelivered = records?.reduce((accumulator, report) => accumulator + Number(report.delivered.split('(')[0]), 0);
        let TotalRto = records?.reduce((accumulator, report) => accumulator + Number(report.rto.split('(')[0]), 0);
        let TotalUndeliverd = records?.reduce((accumulator, report) => accumulator + Number(report.undelivered.split('(')[0]), 0);
        let TotalIntransit = records?.reduce((accumulator, report) => accumulator + Number(report.intransit.split('(')[0]), 0)
        let TotalDeliveredper = (((TotalDelivered) / Totalorder) * 100).toFixed(2)
        let TotalRtoPer = (((TotalRto) / Totalorder) * 100).toFixed(2)
        console.log(TotalUndeliverd,'TotalUndeliverdTotalUndeliverdTotalUndeliverdTotalUndeliverd')
        let TotalUndeliverdPer = (((TotalUndeliverd) / Totalorder) * 100).toFixed(2)
        let TotalIntransitPer = (((TotalIntransit) / Totalorder) * 100).toFixed(2)
        let img = './img/Total.png'
        let courierName = 'Total'
        let array = {
            logoURL: img, courierName, totalOrder: Totalorder, delivered: `${TotalDelivered} (${TotalDeliveredper}%)`, rto: `${TotalRto} (${TotalRtoPer}%)`, undelivered: `${TotalUndeliverd} (${TotalUndeliverdPer}%)`, intransit: `${TotalIntransit} (${TotalIntransitPer}%)`
        }
        records = [array, ...records]
        if (records) {
            downloadExcel(records);
        }
    };

    const downloadExcel = (records) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Carrier name', 'Total Order', 'Delivered (%)', 'RTO (%)', 'Undelivered (%)', 'Intransit (%)'
            ],
            ...records.map((record) => [record.courierName, record.totalOrder, record.delivered, record.rto, record.undelivered, record.intransit,

            ]),
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveExcelFile(excelBuffer, 'AllRecords.xlsx');
    };

    const saveExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <Sidebar>
                <div className='container-fluid' data-aos="fade-down" data-aos-duration="2000" >
                    <h6 className='fw-bold' style={{ color: '#194c63' }}>Carrier Performance</h6>
                    <div className='row bg-light rounded-top shadow d-flex p-3 pb-0' >
                        <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-md-3 d-flex flex-column gap-1 ' >
                                    <label>Date From</label>
                                    <DatePicker id="dateFrom" className='form-control' dateFormat="dd-MMM-yyyy" selected={FromDate} onChange={handleDateChange} />
                                </div>
                                <div className='col-md-3 d-flex flex-column gap-1 ' >
                                    <label>Date To</label>
                                    <DatePicker id="dateTo" className='form-control' dateFormat="dd-MMM-yyyy" selected={ToDate} onChange={handleDateChangeTo} />
                                </div>
                                <div className='col-md-6 filter'>
                                    <LoadingButton
                                        loading={state}
                                        disabled={state}
                                        loadingPosition='center'
                                        onClick={getReport}
                                        className='py-1'
                                        style={{ background: "var(--mycolorOrange)" }}
                                        variant="contained">
                                        <span><RiFilter3Fill className='filter-icon' /></span>Apply
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 align-item-center pt-4'>
                            <div className='row'>
                                <div className='col-md-8'></div>
                                <div className='col-md-4' >
                                    <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
                                        <button className='text-light btn' style={{ background: "var(--mycolorBlue)" }}>
                                            <span><RiDownloadCloudFill className='shipment-icon' /></span>
                                            <span className='text-center fw-normal'>Download Performance</span>
                                        </button>
                                        <div className="dropdown-content con2">
                                            <a onClick={handleDownloadpdf}>PDF</a>
                                            <a onClick={handleDownloadExcel}>EXCEL</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row bg-light rounded-bottom pt-3' >
                        <div className='col-sm-12' style={{ "overflowX": "auto" }}>
                            <table className='table table-bordered text-left' style={{ "fontSize": "12px" }}  >
                                <thead style={{ "background": "rgb(231 242 249)", "fontSize": "14px", borderTop: "1px solid #efefef", borderBottom: "2px solid #f1f1f1" }}>
                                    <tr>
                                        <th>Carrier name</th>
                                        <th>Total Order</th>
                                        <th>Delivered (%)</th>
                                        <th>RTO (%)</th>
                                        <th>Undelivered (%)</th>
                                        <th>Intransit (%)</th>
                                    </tr>
                                  
                                </thead>
                                <tbody>
                                   
                                    {
                                    reportData === null ? '' :
                                            reportData.map((item, index) =>
                                                <tr key={index} style={{ fontSize: index === 0 ? '17px' : '13px' }} className={index === 0 ? 'text-dark' : 'link-style' }>
                                                <td className={index === 0 ? ' FirstRowforTotal' : 'text-success fw-bold fs-6' } ><img src={item.logoURL} height='30px' alt='..' /> {item.courierName}</td>
                                                <td><Link className={index === 0 ? ' FirstRowforTotal' : 'link-style' } target='_blank' to={`/shipments/#${item.courierCode}# #${urldatefrom}#${urldateto}`}>{item.totalOrder}</Link></td>
                                                <td><Link className={index === 0 ? ' FirstRowforTotal' : 'link-style' } target='_blank' to={`/shipments/#${item.courierCode}#DELIVERED#${urldatefrom}#${urldateto}`}>{item.delivered}</Link> </td>
                                                <td><Link className={index === 0 ? ' FirstRowforTotal' : 'link-style' } target='_blank' to={`/shipments/#${item.courierCode}#RTO#${urldatefrom}#${urldateto}`}>{item.rto}</Link> </td>
                                                <td><Link className={index === 0 ? ' FirstRowforTotal' : 'link-style' } target='_blank' to={`/shipments/#${item.courierCode}#UNDELIVERED#${urldatefrom}#${urldateto}`}>{item.undelivered}</Link> </td>
                                                <td><Link className={index === 0 ? ' FirstRowforTotal' : 'link-style' } target='_blank' to={`/shipments/#${item.courierCode}#INTRANSIT#${urldatefrom}#${urldateto}`}>{item.intransit}</Link></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <ToastContainer />
            </Sidebar>
        </>
    )
}
export default CarrierPerformance;
export const InputControl = ({ defaultValue, value, onChange, lableText, type, placeholder }) => {
    return (
        <>
            <div className='col-md-3 d-flex flex-column gap-1' >
                <label>{lableText}</label>
                <input type={type} value={value} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} className='form-control input-fields' />
            </div>
        </>
    )
}