import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CommonURL } from "../commoncomponents/components"
import { LoadingButton } from '@mui/lab';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

function ForgotPasswordSlide2() {
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [OTP, setOTP] = useState('');
    let [state, setState] = useState('');
    let ClientCode = cookies.get('clientCode');
    const Token = 'TbHLhZI8OrTk0bZc4XymwBQKHzKW9XInVVoiR+eEDYP7IopP9N';
    const navigate = useNavigate();

    const changePassword = async () => {
        setState(true);
        const data = { ConfirmPassword, Token, NewPassword, OTP, ClientCode };
        await fetch(`${CommonURL}/ShipSagarApi/ResetPassword`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'SUCCESS') {
                setState(false);
                toast.success(res.message, {
                    position: 'top-center'
                });
                setTimeout(() => {
                    navigate('/login');
                }, 2500);
            } else {
                setState(false);
                toast.error(res.message, {
                    position: 'top-center'
                });
            }
        }).catch((err) => {
            setState(false);
            toast.error(err.message, {
                position: 'top-center'
            });
        });
    };

    return (
        <>
            <div className="container-fluid bg-login vh-100 d-flex justify-content-center align-items-center">
                <div className="row " data-aos="fade-left" data-aos-duration="1000">
                    <div className="col-sm-3 p-4 py-4 main-login text-black rounded">
                        <div className="text-center">
                            <img src="./img/logo-login.png" height='60px' className='img1' alt="..." />
                        </div>
                        <form>
                            <h5 className="fw-bolder text-center pt-3 pb-2">Reset <span style={{ color: "#f39922" }}>Password</span></h5>
                            <div className="form-outline mb-3">
                                <label className="form-label " htmlFor="clientCode">
                                    Enter OTP
                                </label>
                                <input type="text" value={OTP} maxLength='4' onChange={(e) => setOTP(e.target.value)}
                                    placeholder="Enter OTP" id="clientCode" className="form-control" />
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label " htmlFor="clientCode">
                                    New Password
                                </label>
                                <input type="password" maxLength='50' value={NewPassword} onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="New Password" className="form-control" />
                            </div>
                            <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="clientCode">
                                    Confirm Password
                                </label>
                                <input type="password" maxLength='50' value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm Password" className="form-control" />
                            </div>
                            <div>
                                <LoadingButton loading={state} disabled={state}
                                    loadingPosition='center'
                                    onClick={changePassword} className='py-2 my-1 form-control' style={{ background: "#f39922", float: 'right' }} variant="contained">
                                    Change Password
                                </LoadingButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
export default ForgotPasswordSlide2;
