import React, { useEffect, useState } from 'react';
import { CommonURL } from "../../commoncomponents/components";
import Payment from '../Payment/Payment';
import Sidebar from '../SideBar';
import './DashboardStyle.css';
import { FcShipped } from 'react-icons/fc';
import { MdManageSearch } from 'react-icons/md';
import { FaWarehouse, FaCubes } from 'react-icons/fa';
import { AiOutlineException } from 'react-icons/ai';
import { FaReplyAll } from 'react-icons/fa';
import { FaBan } from 'react-icons/fa';
import { FaShippingFast } from 'react-icons/fa';
import { TbTruckOff } from 'react-icons/tb';
import "chart.js/auto";
import { Bar, Pie } from "react-chartjs-2";
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import ReactApexChart from 'react-apexcharts';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Dashboard = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const [Record, setRecord] = useState([]);
    const [boardData, setBoardData] = useState([]);
    const [DateFrom, setDateFrom] = useState(currentDate);
    const [DateTo, setDateTo] = useState(new Date());
    const [chart, setChart] = useState([]);
    var ss = boardData.dailyChartDetails;
    var data = [];
    let urldatefrom = DateFrom.toLocaleDateString('en-US')
    let urldateto = DateTo.toLocaleDateString('en-US')

    if (ss !== undefined && ss !== null) {
        for (let i = 0; i < ss.length; i++) {
            data.push({
                bookingDate: ss[i].bookingDate,
                pending: ss[i].pending,
                delivered: ss[i].delivered,
                totalOrder: ss[i].totalOrder,
                rto: ss[i].rto,
                ofd: ss[i].ofd,
            });
        }
    };

    var bookingDate = data.map((it) => (it.bookingDate));
    var pendingData = data.map((item) => item.pending);
    var deliveredData = data.map((i) => i.delivered);
    var totalOrder = data.map((i) => i.totalOrder);
    if (Record.totalOrder !== '0') {
        var ofdper = ((Record.ofd / Record.totalOrder) * 100).toString();
        ofdper = parseFloat(ofdper).toFixed(2);
        var deliveredper = ((Record.delivered / Record.totalOrder) * 100).toString();
        deliveredper = parseFloat(deliveredper).toFixed(2);
        var undeliveredper = ((Record.undelivered / Record.totalOrder) * 100).toString();
        undeliveredper = parseFloat(undeliveredper).toFixed(2);
        var rtoper = ((Record.rto / Record.totalOrder) * 100).toString();
        rtoper = parseFloat(rtoper).toFixed(2);
        var pendingper = ((Record.pending / Record.totalOrder) * 100).toString();
        pendingper = parseFloat(pendingper).toFixed(2);
        var exceptionper = ((Record.exception / Record.totalOrder) * 100).toString();
        exceptionper = parseFloat(exceptionper).toFixed(2);
        var notfoundper = ((Record.notFound / Record.totalOrder) * 100).toString();
        notfoundper = parseFloat(notfoundper).toFixed(2);
    }
    else {
        ofdper = '0'
        deliveredper = '0'
        undeliveredper = '0'
        rtoper = '0'
        pendingper = '0'
        exceptionper = '0'
        notfoundper = '0'
    };

    const value = {
        labels: bookingDate,
        datasets: [
            {
                label: "Total Order",
                backgroundColor: "rgb(0, 172, 230)",
                borderColor: "black",
                data: totalOrder,
            },
            {
                label: "Delivered Data",
                backgroundColor: "rgb(6, 193, 10)",
                borderColor: "black",
                data: deliveredData,
            },
            {
                label: "Pending Data",
                backgroundColor: "rgb(255, 179, 102)",
                borderColor: "black",
                data: pendingData,
            },

        ],
    };

    let statusData = {
        type: "pie",
        animationEnabled: true,
        exportEnabled: true,
        theme: "dark2",
        labels: ['Pending', 'Delivered', 'RTO', 'OFD', 'UnDelivered ', 'Not Found', 'Exception'],
        datasets: [
            {
                label: "Status Total-Shipments",
                backgroundColor: [Record.pendingColor, Record.deliveredColor, Record.rtoColor, Record.ofdColor, Record.undeliveredColor, Record.notFoundColor, Record.exceptionColor],
                data: [Record.pending, Record.delivered, Record.rto, Record.ofd, Record.undelivered, Record.notFound, Record.exception],
            },
        ],
    };

    var Duration = "10";

    const getChartAPi = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = { ClientCode, Token, DateFrom, DateTo, Duration };
        if (data.Duration) {
            Duration = data.Duration;
        }

        await fetch(`${CommonURL}/ShipSagarApi/GetDashboardChart`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(async (response) => {
                let res = await response.json();
                setBoardData(res);

            })
            .catch((err) => {
            });
    };

    const fetchData = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');
        const data = `{"clientCode" : "${ClientCode}","Token":"${Token}","DateFrom":"${document.getElementById("dateFrom").value}","DateTo":"${document.getElementById("dateTo").value}"}`

        await fetch(`${CommonURL}/ShipSagarApi/GetDashboardData`, {
            method: 'POST',
            body: (data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const responseData = await response.json();
            if (responseData.status === "SUCCESS") {
                setRecord(responseData);
            }
            else {
                throw new Error('Error fetching data from API');
            }
        }).catch((err) => {
        })
    };

    const FilterToData = async () => {
        if (DateFrom && DateTo) {
            await fetchData(DateFrom, DateTo);
            getChart();
        }
    };

    const getData = async () => {
        let currentDate = new Date();
        let DateTo = currentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
        currentDate.setDate(currentDate.getDate() - 7);
        let DateFrom = currentDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

        await fetchData(DateFrom, DateTo);
    };

    const handleChangeDateFilterFrom = (date) => {
        setDateFrom(date);
    };

    const handleChangeDateFilterTo = (date) => {
        setDateTo(date);
    };

    const getChart = async () => {
        let ClientCode = cookies.get('clientCode');
        let Token = cookies.get('Token');

        const data = `{"ClientCode":"${ClientCode}","Token":"${Token}" ,"FromDate":"${document.getElementById("dateFrom").value}" ,"ToDate":" ${document.getElementById("dateTo").value} "}`;
        await fetch(`${CommonURL}/Report/GetCourierPerformanceReport`, {
            method: 'POST',
            body: (data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === "SUCCESS") {
                setChart(res);
            }
            else {
                throw new Error('Error fetching data from API');
            }
        }).catch((err) => {
        })
    };

    var aa = chart.getReport;
    var data = [];

    if (aa !== undefined && aa !== null) {
        for (let i = 0; i < aa.length; i++) {
            data.push({
                courierName: aa[i].courierName,
                delivered: aa[i].delivered,
                intransit: aa[i].intransit,
                totalOrder: aa[i].totalOrder,
                rto: aa[i].rto,
                undelivered: aa[i].undelivered,
            });
        }
    };
    var courierName = data.map((i) => (i.courierName));
    var delivered = data.map((i) => i.delivered);
    var intransit = data.map((i) => i.intransit);
    var rto = data.map((i) => i.rto);
    var undelivered = data.map((i) => i.undelivered);

    const series = [
        {
            name: 'Delivered(%)',
            data: delivered,
        },
        {
            name: 'RTO(%)',
            data: rto,
        },
        {
            name: 'Intransit(%)',
            data: intransit,
        },
        {
            name: 'Undelivered(%)',
            data: undelivered,
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        xaxis: {
            categories: courierName
        },
        colors: ['#11d04b', '#f5e029', '#33d1fe', '#b50000'],
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            offsetX: 50
        }
    };

    useEffect(() => {
        getData();
        FilterToData();
        getChartAPi();
        getChart();
    }, []);

    return (
        <div >
            <Sidebar>
                <h6 className='fw-bold' style={{ color: '#194c63' }}>Dashboard</h6>
                <div className=' p-2' style={{ "position": "relative", "zIndex": "99999" }} data-aos="fade-down" data-aos-duration="2000">
                    <div className='row '>
                        <div className='col-sm-1'> </div>
                        <div className='col-sm-6 '>
                            <div className='row '>
                                <div className='col-md-3 d-flex flex-column gap-1 '>
                                    <label>Date From</label>
                                    <DatePicker id="dateFrom" className='form-control input-fields' dateFormat="dd-MMM-yyyy" selected={DateFrom} onChange={handleChangeDateFilterFrom} />
                                </div>
                                <div className='col-md-3 d-flex flex-column gap-1 '>
                                    <label>Date To</label>
                                    <DatePicker id="dateTo" className='form-control input-fields' dateFormat="dd-MMM-yyyy" selected={DateTo} onChange={handleChangeDateFilterTo} />
                                </div>
                                <div className='col-md-1 my-auto'>
                                    <label></label>
                                    <button className='btn d-flex py-1 mx-auto' onClick={FilterToData} style={{ "backgroundColor": "var(--mycolorOrange)", color: "white" }}>
                                        <MdManageSearch className='search-icon' /> <span className='showSearch'>Search</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-5 pt-4'>
                            <Payment />
                        </div>
                    </div>
                    <div className="row shadow p-3 pb-0 mt-4">
                        <div className="col-md mb-4 " >
                            <Link className='link-style' target='_blank' to={`/shipments/# # #${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.totalOrderColor }} >
                                    <div className="card-body" >
                                        <div className="row text-center text-dark"  >
                                            <b>TOTAL ORDER</b>
                                            <div className="mb-2">
                                                {Record.totalOrder}
                                            </div>
                                            <FaCubes style={{ fontSize: "45px", color: Record.totalOrderColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #OFD#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left  py-2" style={{ color: Record.ofdColor }}>
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <b>OFD</b>
                                            <div className=" mb-2">
                                                {Record.ofd} ({ofdper}%)
                                            </div>
                                            <FaShippingFast style={{ fontSize: "45px", color: Record.ofdColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4 ">
                            <Link className='link-style' target='_blank' to={`/shipments/# #DELIVERED#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.deliveredColor }}>
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <b>DELIVERED</b>
                                            <div className="mb-2">
                                                {Record.delivered} ({deliveredper}%)
                                            </div>
                                            <FcShipped style={{ fontSize: "45px", color: Record.deliveredColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #UNDELIVERED#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.undeliveredColor }} >
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <b>UNDELIVERED</b>
                                            <div className="mb-2">
                                                {Record.undelivered} ({undeliveredper}%)
                                            </div>
                                            <TbTruckOff style={{ fontSize: "45px", color: Record.undeliveredColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #RTO#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left  py-2" style={{ color: Record.rtoColor }} >
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <span>
                                                RTO</span>
                                            <div className=" mb-2">
                                                {Record.rto} ({rtoper}%)
                                            </div>
                                            <FaReplyAll style={{ fontSize: "45px", color: Record.rtoColor }} />                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #PENDING#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.pendingColor }} >
                                    <div className="card-body">
                                        <div className="row text-center">
                                            <span>PENDING</span>
                                            <div className='mb-2'>
                                                {Record.pending} ({pendingper}%)
                                            </div>
                                            <FaWarehouse style={{ fontSize: "45px", color: Record.pendingColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #EXCEPTION#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.exceptionColor }}>
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <b>EXCEPTION</b>
                                            <div className="mb-2">
                                                {Record.exception} ({exceptionper}%)
                                            </div>
                                            <AiOutlineException style={{ fontSize: "45px", color: Record.exceptionColor }} />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md mb-4">
                            <Link className='link-style' target='_blank' to={`/shipments/# #NOTFOUND#${urldatefrom}#${urldateto}`}>
                                <div className="card card-radius pt-3 border-left py-2" style={{ color: Record.notFoundColor }}>
                                    <div className="card-body">
                                        <div className="row text-center text-dark">
                                            <b>NOT FOUND</b>
                                            <div className="mb-2">
                                                {Record.notFound} ({notfoundper}%)
                                            </div>
                                            <FaBan style={{ fontSize: "45px", color: Record.notFoundColor }} />                  </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='row shadow mt-3 pt-3'>
                        <div className="col-xl-8 col-lg-8">
                            <div className="card mb-4">
                                <h6 className="m-0 fw-bold pt-2  text-center">Days Wise Shipments Status</h6>
                                <div className="card-body">
                                    <div className="chart-area" >
                                        <Bar data={value} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4'>
                            <div className="card  mb-4">
                                <h6 className="m-0 fw-bold pt-2 text-center  ">Status - Total Shipment</h6>
                                <Pie className='py-2 mt-2' data={statusData} />
                            </div>
                        </div>
                        {/* Search Box*/}
                    </div>
                    <div className='row shadow mt-3'>
                        <div className="col-xl-12 col-lg-12 col-sm-12">
                            <div className="card mb-4">
                                <h6 className="fw-bold my-3 text-center">Carrier Performance Status</h6>
                                <div className="card-body" >
                                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}
export default Dashboard;
export const InputControl = ({ defaultValue, value, onChange, lableText, type, placeholder }) => {
    return (
        <>
            <div className='col-md-3  me-1' >
                <label>{lableText}</label>
                <input type={type} value={value} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} className='form-control input-fields' />
            </div>
        </>
    )
}