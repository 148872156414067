import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import { LoadingButton } from '@mui/lab';
import { CommonURL } from "../commoncomponents/components"
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Login = () => {
    const [ClientCode, setClientCode] = useState('');
    const [Password, setPassword] = useState('');
    const Token = 'TbHLhZI8OrTk0bZc4XymwBQKHzKW9XInVVoiR+eEDYP7IopP9N';
    const navigate = useNavigate();
    let [state, setState] = useState('');
  
    const checkLogin = async () => {
        setState(true)
        const data = { ClientCode, Password, Token }
        await fetch(`${CommonURL}/ShipSagarApi/UserLogin`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        }).then(async (response) => {
            const res = await response.json()
            if (res.status === 'SUCCESS') {
                setState(false)
                await cookies.set('Token', res.loginSessionID);            
                await cookies.set('clientCode', res.clientCode);              
                await cookies.set('clientName', res.clientName);              
                navigate('/dashboard')            
            } else {
                setState(false)
                toast.error(
                    'Invalid ClientCode or password. Please try again.!', {
                    position: 'top-center'
                });
            }
        }).catch((err) => {
            setState(false)
            toast.error(
                err.message, {
                position: 'top-center'
            });
        });
    }

    const handler = (event) => {
        if (event.key === 'Enter') {
            checkLogin();
        } 
    };

    return (
        <>
            <div className="container-fluid bg-login vh-100 d-flex justify-content-center align-items-center" >
                <div className="row" data-aos="zoom-in" data-aos-duration="1000" >
                    <div className="col-sm-3 px-4 py-5 main-login text-black rounded">
                        <div className="text-center">
                            <img src="./img/logo-login.png" className='img1' alt="..." />
                        </div>
                        <div className="">
                            <form method='POST' className='' >
                                <h5
                                    className="fw-bolder text-center pt-3 pb-2"
                                ><span style={{ color: "#f7991d" }}>Welcome</span> To Ship Sagar
                                </h5>
                                <div className="form-outline mb-3">
                                    <label className="form-label" htmlFor="clientCode">
                                        Client Code
                                    </label>
                                    <input
                                        type="email"
                                        maxLength='10'
                                        value={ClientCode}
                                        onChange={(e) => setClientCode(e.target.value)}
                                        placeholder="Client Code"
                                        id="clientCode"
                                        className="form-control form-control fs-6"
                                    />
                                </div>
                                <div className="form-outline mb-2">
                                    <label className="form-label" htmlFor="password">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        value={Password}
                                        maxLength='50'
                                        onKeyPress={(e) => handler(e)}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        className="form-control form-control fs-6"
                                        placeholder="Password"
                                    />
                                </div>
                                <div className="pt-3 mb-2">
                                    <LoadingButton loading={state} disabled={state} loadingPosition='center' onClick={checkLogin} className='py-1 form-control' style={{ background: "#f7991d" }} variant="contained"> <b className='fs-6 rounded'> Login </b> </LoadingButton>
                                </div>
                                    <Link className="text-muted" to="/checkdata">
                                        Forgot password?
                                    </Link>                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
};

export default Login;

