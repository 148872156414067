import * as React from 'react';
import 'jspdf-autotable';
import { toast } from 'react-toastify';


//export shipment data 
export const ExportPdf = async (apiUrl, requestBody, requestHeaders) => {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });
        if (response.ok) {
            const responseData = await response.json();

            if (responseData.status === 'SUCCESS') {
                if (Array.isArray(responseData.filterShipment)) {
                    const records = responseData.filterShipment;
                    
                    return records;
                } else {
                   // console.error('Invalid response format. Expected an array of records.');
                }
            } else if (responseData.status === 'ERROR') {
                if (responseData.message === 'EXPIRED') {
                    // navigate("/login");
                } else {
                    toast.error(responseData.message, {
                        position: 'top-center',
                    });
                }
            }
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (err) {
        //console.error(err);
        toast.error(err.message, {
            position: 'top-center',
        });
    }
};

/*export ExportCarrierPerformance*/

export const ExportCarrierPerformance = async (apiUrl, requestBody, requestHeaders) => {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });

        if (response.ok) {
            const responseData = await response.json();
           
            if (responseData.status === 'SUCCESS') {
                if (Array.isArray(responseData.getReport)) {
                    const records = responseData.getReport;
                    return records;
                } else {
                   // console.error('Invalid response format. Expected an array of records.');
                }
            } else if (responseData.status === 'ERROR') {
                if (responseData.message === 'EXPIRED') {
                    // navigate("/login");
                } else {
                    toast.error(responseData.message, {
                        position: 'top-center',
                    });
                }
            }
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (err) {
       //console.error(err);
        toast.error(err.message, {
            position: 'top-center',
        });
    }
};

/* export CourierList*/

export const ExportCourier = async (apiUrl, requestBody, requestHeaders) => {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData.status === 'SUCCESS') {
                if (Array.isArray(responseData.getCourierList)) {
                    const records = responseData.getCourierList;
                    return records;
                } else {
                    // console.error('Invalid response format. Expected an array of records.');
                }
            } else if (responseData.status === 'ERROR') {
                if (responseData.message === 'EXPIRED') {
                    // navigate("/login");
                } else {
                    toast.error(responseData.message, {
                        position: 'top-center',
                    });
                }
            }
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (err) {
        //console.error(err);
        toast.error(err.message, {
            position: 'top-center',
        });
    }
};


export const ExportNDRReport = async (apiUrl, requestBody, requestHeaders) => {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData.status === 'SUCCESS') {
                if (Array.isArray(responseData.getNDRReport)) {
                    const records = responseData.getNDRReport;
                    return records;
                } else {
                    // console.error('Invalid response format. Expected an array of records.');
                }
            } else if (responseData.status === 'ERROR') {
                if (responseData.message === 'EXPIRED') {
                    // navigate("/login");
                } else {
                    toast.error(responseData.message, {
                        position: 'top-center',
                    });
                }
            }
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (err) {
        //console.error(err);
        toast.error(err.message, {
            position: 'top-center',
        });
    }
};

