import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CommonURL } from "../commoncomponents/components"
import { LoadingButton } from '@mui/lab';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const ForgotPasswordSlide1 = () => {
    const [ClientCode, setClientCode] = useState('');
    let [state, setState] = useState('');
    const Token = 'TbHLhZI8OrTk0bZc4XymwBQKHzKW9XInVVoiR+eEDYP7IopP9N';
    const navigate = useNavigate();

    const checkData = async () => {
        const data = { ClientCode, Token }
        setState(true)
        await fetch(`${CommonURL}/ShipSagarApi/GetForgetPasswordOTP`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        }).then(async (response) => {
            const res = await response.json()
            if (res.status === 'SUCCESS') {
                setState(false)
                await cookies.set('clientCode', ClientCode);
                toast.success(res.message, {
                    position: 'top-center'
                });
                setTimeout(() => {
                navigate('/forgotpassword')
                },3000)
            } else {
                toast.error(res.message, {
                    position: 'top-center'
                });
                setState(false)
            }
        }).catch((err) => {
            setState(false)
            toast.error(err.message, {
                position: 'top-center'
            });
        });
    }

    return (
        <>
            <div className="container-fluid bg-login vh-100 d-flex justify-content-center align-items-center">
                <div className="row" data-aos="fade-right" data-aos-duration="1000" >
                    <div className="col-sm-3  px-4 py-5 main-login text-black rounded">
                        <div className="text-center">
                            <img src="./img/logo-login.png" height='50px' className='img1' alt="..." />
                        </div>
                        <div className="">
                            <form>
                                <h5 className="fw-bolder text-center pt-3 pb-2">Forgot <span style={{ color: "#f39922" }}>Password</span></h5>
                                <div className="form-outline mb-2">
                                    <label className="form-label mb-0" htmlFor="clientCode">
                                        Client Code
                                    </label>
                                    <input type="text" maxLength='10' value={ClientCode} onChange={(e) => setClientCode(e.target.value)}
                                        placeholder="Client Code" id="clientCode" className="form-control" />
                                </div>
                                <div>
                                    <LoadingButton loading={state} disabled={state}
                                        loadingPosition='center'
                                        onClick={checkData} className='py-2 mt-2 form-control' style={{ background: "#f39922", float: 'right' }} variant="contained">
                                       NEXT 
                                    </LoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};
export default ForgotPasswordSlide1;
