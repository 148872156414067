import React from 'react';
import Sidebar from '../SideBar';
import './ApiStyle.css';

const ApiDocuments = () => {
    //pushshipment
    var reqq = String.raw`
    {
      "Token": "",
      "ClientCode": "",
      "CourierCode": "",
      "TrackingNo": "",
      "OrderNo": "",
      "CustomerName": "",
      "EmailID": "",
      "ShipmentType": "",
      "MobileNo": "",
      "CountryName": "",
      "CompanyName": ""
    }
    `;
    var responsedata = String.raw`
    Success Response
    ------------------------
    {
        "status": "success",
        "message": "Data has been recorded successfully"
    }

   Failure Response
   ------------------------
    {
        "Status":"ERROR",
        "Message":"please try again later"
    }
    `;
    //trackshipment
    var reqTracking = String.raw`
    {
        "Token":"",
        "ClientCode":"",
        "TrackingNo":""
    }
    `;
    var resTracking = String.raw`
   Success Response
   ------------------------
    {
      "status": "SUCCESS",
      "message": "",
      "TrackingDetails": [
        {
          "ClientCode": "C1001",
          "TrackingNo": "324049418658",
          "CourierCode": "ATS",
          "TrackingHistory": [
            {
              "ActionDate": "16-May-2023",
              "ActionTime": "12:27",
              "ActionLocation": "",
              "ActionDescription": "Label Created"
            },
            {
              "ActionDate": "16-May-2023",
              "ActionTime": "15:51",
              "ActionLocation": "",
              "ActionDescription": "Package picked up"
            },
            {
              "ActionDate": "16-May-2023",
              "ActionTime": "19:43",
              "ActionLocation": "New Delhi",
              "ActionDescription": "Package arrived at the carrier facility"
            }
          ]
        }
      ]
    }

    Failure Response
    -------------------------
    {
      "Status": "ERROR",
      "Message": "please try again later"
    }
    `;
    //courierlist

    var reqcourier = String.raw`
    {
        "Token":"",
        "ClientCode":""
    }
    `;
    var resCourier = String.raw`
   Success Response
   -----------------------
    {
    "status": "SUCCESS",
    "message": "48 Record Found",
    "getCourier": [
        {
            "courierName": "Amazon Tracking Services",
            "courierCode": "ATS"
        },
        {
            "courierName": "ARAMEX",
            "courierCode": "ARAMEX"
        }
      ]
    }

    Failure Response
    -------------------
    {
      "Status": "ERROR",
      "Message": "please try again later"
    }
    `;
    return (
        <>
            <Sidebar>
                <div className="row" style={{ "padding": "0px 12px" }} >
                    <div className="col-lg-12 main-Head">
                        <div className="page-title-box p-1">
                            <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>PUSH SHIPMENT</h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 "  >
                    <div className="card">
                        <div className="card-body">
                            <div className="row pt-4">
                                <div className="col-lg-5 col-md-5 col-sm-5 mx-auto">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mt-1">
                                            <div className="row">
                                                <h5><b>Sample For Api Documentation  (Method POST)</b></h5>
                                                <h6 style={{ color: "blue" }}><b><pre>1. http://app.shipsagar.com/api/Web/PushShipment</pre></b></h6>
                                                <b>Method : Post</b>
                                                <b> Content Type: application/json</b>
                                                <br />
                                                <br />
                                                <h5 style={{ color: "#003d99" }}> <b>Request String:</b></h5>
                                                <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>
                                                    {reqq}
                                                </pre>
                                                <div className="col-sm-12 " style={{ overflow: 'auto' }} >
                                                    <h5 style={{ color: "#003d99" }}> <b>Response String:</b></h5>
                                                    <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>
                                                        {responsedata}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h2>Request Documents Description</h2>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="x_content">
                                            <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.4' }}>
                                                    <ul className="sequential-list" >
                                                        <li>You will get the <b>token</b> from the client profile page token name is api key.</li>
                                                        <li>You will get the <b>client code</b> you go from the client profile page.</li>
                                                        <li>The term <b>"Courier Code"</b> refers to the specific identification code assigned to a courier or logistics company through which your courier or parcel will be delivered. It can represent various courier companies such as DTDC, FedEx, DHL, UPS, or any other logistics service provider.</li>
                                                        <li>If you require a <b>tracking number</b>, it will be provided to you from the shipment's page.</li>
                                                        <li>If you require a <b>customer name</b>, it will be provided to you from the shipment's page.</li>
                                                        <li>If you require an <b>email ID</b>, it will be provided to you from the shipment's page.</li>
                                                        <li>If you require the <b>shipment type</b>, it will be provided to you from the shipment's page. The shipment type refers to the method or mode by which your courier is being sent, such as by road or by train.</li>
                                                        <li>You will get the <b>mobile number</b> from the client profile page.</li>
                                                        <li>If you require a <b>country name</b>, it will be provided to you from the shipment's page.</li>
                                                        <li>If you require a <b>company name</b>, it will be provided to you from the shipment's page.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="x_panel">
                                                    <div className="x_title">
                                                        <h2>Response Documents Description</h2>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="x_content">
                                                        <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.7' }}>
                                                                <h6><b>Success</b></h6>
                                                                <li><b> The status is "success," which means there are no errors.</b></li>
                                                                <li><b>The data sent by you has been recorded successfully .</b></li>
                                                                <br />
                                                                <h6><b>Failed</b></h6>
                                                                <li><b> The status is "failed," which means there is an error.</b></li>
                                                                <li><b>Status failed ,please try again later .</b></li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ "padding": "0px 12px" }} >
                    <div className="col-lg-12 main-Head">
                        <div className="page-title-box p-1">
                            <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>TRACK SHIPMENT</h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 "  >
                    <div className="card">
                        <div className="card-body">
                            <div className="row pt-4">
                                <div className="col-lg-5 col-md-5 col-sm-5 mx-auto">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mt-1">
                                            <div className="row">
                                                <h5><b>Sample For Api Documentation  (Method POST)</b></h5>
                                                <h6 style={{ color: "blue" }}><b><pre>2. http://app.shipsagar.com/api/Web/TrackShipment</pre></b></h6>

                                                <b>Method : Post</b>
                                                <b> Content Type: application/json</b>
                                                <br />
                                                <br />
                                                <h5 style={{ color: "#003d99" }}> <b>Request String:</b></h5>
                                                <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>
                                                    {reqTracking}
                                                </pre>
                                                <div className="col-sm-12 " style={{ overflow: 'auto' }} >
                                                    <h5 style={{ color: "#003d99" }}> <b>Response String:</b></h5>
                                                    <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>

                                                        {resTracking}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h2>Request Documents Description</h2>

                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="x_content">
                                            <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.7' }}>
                                                    <ul className="sequential-list" >
                                                        <li>You will get the <b>token </b>from the client profile page token name is api key.</li>
                                                        <li>The term <b>"Client Code" </b>refers to the specific identification code ,<br />You will get the client code you go from the client profile page.</li>
                                                        <li>If you require a<b> tracking number, </b>it will be provided to you from the shipment's page.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="x_panel">
                                                    <div className="x_title">
                                                        <h2>Response Documents Description</h2>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="x_content">
                                                        <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.7' }}>
                                                                <br />
                                                                <h6><b>Success</b></h6>
                                                                <ul className="sequential-list" >
                                                                    <li><b> The status is"success," which means there are no errors and 3 Record Found.</b></li>
                                                                    <li><b>You will get all the details related to tracking on the success status. For example: <br />
                                                                        "ActionDate": "16-May-2023"<br />
                                                                        "ActionTime": "15:51"<br />
                                                                        "ActionLocation": ""<br />
                                                                        "ActionDescription": "Package picked up".
                                                                    </b></li>
                                                                </ul>
                                                                <br />
                                                                <h6><b>Failed</b></h6>
                                                                <ul className="sequential-list" >
                                                                    <li><b> The status is "failed," which means there is an error.</b></li>
                                                                    <li><b>Status failed ,please try again later .</b></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ "padding": "0px 12px" }} >
                    <div className="col-lg-12 main-Head">
                        <div className="page-title-box p-1">
                            <h6 className="mb-sm-0 heading fw-bold" style={{ "color": "white" }}>COURIER LIST </h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 "  >
                    <div className="card">
                        <div className="card-body">
                            <div className="row pt-4">
                                <div className="col-lg-5 col-md-5 col-sm-5 mx-auto">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mt-1">
                                            <div className="row">
                                                <h5><b>Sample For Api Documentation  (Method POST)</b></h5>
                                                <h6 style={{ color: "blue" }}><b><pre>3. http://app.shipsagar.com/api/Web/GetCourier</pre></b></h6>
                                                <b>Method : Post</b>
                                                <b> Content Type: application/json</b>
                                                <br />
                                                <br />
                                                <h5 style={{ color: "#003d99" }}> <b>Request String:</b></h5>
                                                <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>
                                                    {reqcourier}
                                                </pre>
                                                <div className="col-sm-12 " style={{ overflow: 'auto' }} >
                                                    <h5 style={{ color: "#003d99" }}> <b>Response String:</b></h5>
                                                    <pre style={{ background: "black", color: "white", borderRadius: "7px" }}>
                                                        {resCourier}
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h2>Request Documents Description</h2>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="x_content">
                                            <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.7' }}>
                                                    <li>You will get the <b>token </b>from the client profile page token name is api key.</li>
                                                    <li>You will get the <b>client code </b>you go from the client profile page.</li>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="x_panel">
                                                    <div className="x_title">
                                                        <h2>Response Documents Description</h2>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="x_content">
                                                        <div className="col-md-12 col-sm-12 accordion-collapse collapse show" id="collapseGrid" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="form-group ps-3" style={{ fontSize: "14px", lineHeight: '1.4' }}>
                                                                <br />
                                                                <h6><b>Success</b></h6>
                                                                <ul className="sequential-list" >
                                                                    <li><b> The status is"success," which means there are no errors and 3 Record Found.</b></li>
                                                                    <li><b>The term "Courier Code" refers to the specific identification code assigned to a courier or logistics company
                                                                        through which your courier or parcel will be delivered. <br />
                                                                        It can represent various courier companies such as DTDC, UPS, or any other Logistic service provider...</b></li>
                                                                </ul>
                                                                <br />
                                                                <h6><b>Failed</b></h6>
                                                                <ul className="sequential-list" >
                                                                    <li><b> The status is "failed," which means there is an error.</b></li>
                                                                    <li><b>Status failed ,please try again later .</b></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
};
export default ApiDocuments;