import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const ProtectedRoutes = (Props) => {

    const navigate = useNavigate()

    const checkLogin = async () => {
        const Login = await cookies.get('Token');
        if (!Login) {
            navigate('/login')
        };
    }

    useEffect(() => { checkLogin() })
    const { Component } = Props;

    return (
        <>
            <Component/>
        </>
    )
}

export default ProtectedRoutes